<template>
    <div ref=map-container class="map-container" v-on:mouseleave="leaveMasterMap" :data-alias="editorAlias || null">
      <el-form-item
        prop="mapValidator"
        style="margin: 0;"
        :rules="[{ validator: inputValidator, trigger: 'blur', message: 'Невозможно сохранить некорректную геометрию' }]"
      ></el-form-item>
      <transition name="slide-fade">
        <tools-panel
          v-show="showToolsPanel"
          class="tools-panel"
          ref="tools_panel"
          :layers="layers"
          :expandLayersByDefault="expandLayersByDefault"
          :toolsPanelWidth="toolsPanelWidth"
          :showLayersOpacity="showLayersOpacity"
          :interactiveObjects="allActiveFeatures"
          :mobileMode="mobileMode"
          :map="map"
          :activeLayers="activeLayers"
          :is-fullscreen="isExpanded"
          :featureMetrics="featureMetrics"
          :hide-layers="hideLayerList"
          :activeLayersLength="activeLayersLength"
          :active-layer-guid="activeLayerGuid"
          :enable-show-geometry-settings="enableShowGeometrySettings"
          :context-menu-settings="layerContextMenuSettings"
          :showToolsPanel="showToolsPanel"
          :vertexNumberingStyle="vertexNumberingStyle"
          @show-tools-panel="showHideLayersButton"
          @layer-select="onLayerSelect"
          @zoom-on-feature="zoomOnFeature"
          @delete-geometry="deleteGeometry"
          @change-layer-opacity="changeLayerOpacity"
          @highlight-features="highlightFeatures"
          @unhighlight-features="unhighlightFeatures"
          @export="exportFromToolsPanel"
          @export-layer="exportLayer"
          @open-card="openCardFromPanel"
          @save-geometry="saveGeometry"
          @apply-filter="applyDynamicFilter"
          @intersection-feature="intersectionFeature"
          @tools-panel-resized=setComponentSize
          @calculate-features="calculateFeatures"
          @focus-on-layer="focusOnLayer"
          @copy-geometry="copyGeometry"
          @click-on-layer="clickOnLayerOnTree"
          @layer-separated="layerSeparated"
        ></tools-panel>
      </transition>
      <div ref="street-view" id="street-view" :class="streetViewContainerClass"></div>
      <div
        :tabindex="tabIndex"
        ref="map"
        :id="guid"
        :class="mapClass"
        @keydown.ctrl.67="copyActiveFeaturesGeoJSON"
        @keydown.ctrl.86="pasteGeoJSONInActiveLayer"
      ></div>
      <zoom
        v-if="showZoomControl"
        class="zoom"
        @change="changeZoom"
        :mobileMode="mobileMode"
        :zoomValue="zoomValue"
      ></zoom>
      <tools
        ref="tools"
        :settings="tools"
        :style="toolsTrStyle"
        :mobileMode="mobileMode"
        :toolsPosition="toolsPosition"
        :checkMarks="toolsCheckMarks"
        :activated="activeInteractiveType"
        :componentSize="componentSize"
        :is-fullscreen="isExpanded"
        :restrictExport="restrictExportData"
        @drawing-geometry="drawingGeometry"
        @measurement="measurement"
        @layers="showHideLayersButton"
        @google-street-view="googleStreetView"
        @expand="expand"
        @position-on="showPositionOnWindow"
        @delete-features="deleteFeatures"
        @change-selection-type="changeSelectInteractionType"
        @print="print"
        @import-features="importFeatures"
        @export-features="exportFeatures"
        @search="search"
        @search-component="searchToolComponent"
        @custom-button="doAction"
      />
      <cadastr-object-panel
        :area="cadastrInfo.area"
        :building="cadastrInfo.building"
        :zouit="cadastrInfo.zouit"
        v-if="showCadastrPanel"
        @close-cadastr-object-panel="closeCadastrPanel"
      ></cadastr-object-panel>
      <footer-panel
        ref="footerPanel"
        :coordinates="footerData.coordinates"
        :cs="footerData.targetCS"
        @change-target-cs="changeFooterTargetCS"
        v-if="!mobileMode && showMouseCoordinates"
        :userCSs="userCSs"
      ></footer-panel>
      <import-features
        ref="import_features"
        :userCSs="userCSs"
        :importLayerGeometryField="importLayerGeometryField"
        :defaultImportSettings="importDefaultImportSettings"
        @import-geojson-features="importGeoJSONFeatures"
        @import-wkt-string="importWKTString"
        ></import-features>
      <export-features-window
        ref="export_features"
        :userCSs="userCSs"
        ></export-features-window>
      <el-dialog
        title="Пересечение геометрий"
        top="10vh"
        :modal="false"
        :visible.sync="intersection.showWindow">
        {{intersection.message}}
        <div v-for="l in intersection.layers">{{ l }}</div>
      </el-dialog>
      <position-on
        :map="map"
        :user-c-ss="userCSs"
        :address-settings="positionOnAddressSettings"
        ref="position-on-window"
        @position-on="positionOn"
      ></position-on>
      <create-geometry-by-vertices
        :map="map"
        :user-c-ss="userCSs"
        :layer="createGeometryByVertices.layer"
        :register-c-s="loadAndRegisterCS"
        ref="create-geometry-by-vertices"
        @geometry-added="refreshGeometryField"
        ></create-geometry-by-vertices>
    </div>
  </template>

<script>
import MapManager from '@bingo_soft/mapmanager'
import PropertiesMixin from '@/components/InterfaceEditor/components/PropertiesMixin'
import Zoom from '@/components/InterfaceEditor/components/basic/Map/Zoom'
import { easeOut } from 'ol/easing.js'
import Tools from '@/components/InterfaceEditor/components/basic/Map/Tools/index'
import { NodeLayerType } from '@/components/InterfaceEditor/components/basic/Map/NodeLayerType'
import SourceType from '@bingo_soft/mapmanager/src/Domain/Model/Source/SourceType'
import * as OlProj from 'ol/proj'
import * as google from 'google-maps'
import openCard_mixin from '@/components/InterfaceEditor/components/openCard_mixin'
import ToolsPanel from '@/components/InterfaceEditor/components/basic/Map/ToolsPanel/index'
import FeatureCollection from '@bingo_soft/mapmanager/src/Domain/Model/Feature/FeatureCollection'
import PositionOn from './Tools/PositionOn/index'
import CreateGeometryByVertices from './Tools/createGeometryByVertices'
import { APIClient, HTTPMethod } from '@/core/infrastructure/api/APIClient'
import { DotNetSpatialServiceAPI } from '@/core/infrastructure/api/modules/DotNetSpatialServiceAPI'
import { RosreestrAPI } from '@/core/infrastructure/api/modules/RosreestrAPI'
import { MapEditorAPI } from '@/core/infrastructure/api/modules/MapEditorAPI'
import { DataAPI } from '@/services/RegistryTable/infrastructure/api/DataAPI'
import CadastrObjectPanel from './CadastrObjectPanel'
import FilterBuilder, { EComponentTypes } from '@/components/InterfaceEditor/components/utils'
import FooterPanel from './footerPanel'
import ImportFeatures from './Tools/importFeatures'
import ExportFeaturesWindow from './ExportFeaturesWindow'
import conditionsMixin from '@/components/InterfaceEditor/components/conditions_mixin'

import CursorType from '@bingo_soft/mapmanager/src/Domain/Model/Map/CursorType'
import EventType from '@bingo_soft/mapmanager/src/Domain/Model/EventHandlerCollection/EventType'
import InteractionType from '@bingo_soft/mapmanager/src/Domain/Model/Interaction/InteractionType'
import ExportType from '@bingo_soft/mapmanager/src/Domain/Model/Map/ExportType'
import GeometryFormat from '@bingo_soft/mapmanager/src/Domain/Model/Feature/GeometryFormat'

import { DEFAULT_NODES } from '@/components/InterfaceEditor/components/editor/MapLayers/Configurator'
import ActionExecutor from '@/core/infrastructure/service/ActionExecutor'
import refreshComponentsMixin from '@/components/InterfaceEditor/components/refreshComponentsMixin'
import Utils from 'element-ui/lib/utils/aria-utils'

import PointStyleImageQueryRepository from '@/core/infrastructure/domain/repository/modules/PointStyleImageQueryRepository'

const INTERACTION_MODES = {
  MEASURE: {
    LINE: 'distance',
    SQUARE: 'area'
  },
  EDIT_BY_VERTICES: 'EditByVertices',
  EDIT: 'Edit',
  DRAWING: {
    POINT: 'Point',
    LINESTRING: 'LineString',
    POLYGON: 'Polygon',
    CIRCLE: 'Circle'
  },
  SELECT: {
    POINT: 'singleclick',
    RECTANGLE: 'rectangle',
    POLYGON: 'polygon',
    CIRCLE: 'circle',
    INTERSECTION: 'intersection',
    ROSREESTR: 'rosreestr'
  },
  DEFINE_ADDRESS: 'defineAddress',
  CREATE_TEXT_BOX: 'CreateTextBox'
}
const INTERACTION_MODES_OPTIONS = [
  { id: INTERACTION_MODES.MEASURE.LINE, name: 'Измерение дистанции' },
  { id: INTERACTION_MODES.MEASURE.SQUARE, name: 'Измерение площади' },
  { id: INTERACTION_MODES.EDIT_BY_VERTICES, name: 'Редактирование по вершинам' },
  { id: INTERACTION_MODES.DRAWING.POINT, name: 'Рисование точки' },
  { id: INTERACTION_MODES.DRAWING.LINESTRING, name: 'Рисование линии' },
  { id: INTERACTION_MODES.DRAWING.POLYGON, name: 'Рисование полигона' },
  { id: INTERACTION_MODES.DRAWING.CIRCLE, name: 'Рисование окружности' },
  { id: INTERACTION_MODES.SELECT.POINT, name: 'Выделение точкой' },
  { id: INTERACTION_MODES.SELECT.RECTANGLE, name: 'Выделение прямоугольником' },
  { id: INTERACTION_MODES.SELECT.POLYGON, name: 'Выделение полигоном' },
  { id: INTERACTION_MODES.SELECT.CIRCLE, name: 'Выделение окружностью' },
  { id: INTERACTION_MODES.SELECT.INTERSECTION, name: 'Поиск пересечений' },
  { id: INTERACTION_MODES.SELECT.ROSREESTR, name: 'Сведения росреестра' },
  { id: INTERACTION_MODES.DEFINE_ADDRESS, name: 'Определение адреса' }
]

export default {
  name: 'map',
  components: { CadastrObjectPanel, Tools, Zoom, ToolsPanel, FooterPanel, ImportFeatures, PositionOn, CreateGeometryByVertices, ExportFeaturesWindow },
  mixins: [PropertiesMixin, openCard_mixin, conditionsMixin, refreshComponentsMixin],
  inject: {
    isEditor: {
      default: () => false
    },
    getInterfaceEditorVersion: {
      default: () => () => 1
    },
    getCard: {
      default: () => {}
    },
    getParentDashboard: {
      default: () => {}
    },
    getViewer: {
      default: () => {
        return {}
      }
    },
    getParentContext: {
      default: () => {}
    },
    addMainTab: {
      default: () => {}
    },
    updateTab: {
      default: () => {}
    },
    tabs: {
      default: () => {}
    },
    activeTab: {
      default: () => {}
    },
    closeTab: {
      default: () => {}
    },
    openedCards: {
      default: () => {}
    },
    cancelChanges: {
      default: () => {}
    },
    openRegistryCard: {
      default: () => {}
    },
    openDashboardCard: {
      default: () => {}
    },
    openTabModalWindow: {
      default: () => {}
    },
    forceUpdateSettingsPanel: {
      default: () => () => {}
    },
    getContainersStore: {
      default: () => () => {}
    },
    getInterfaceWrapper: {
      default: () => () => {}
    },
    getComponents: {
      default: () => () => {}
    },
    getDashboardComponents: {
      default: () => () => {}
    }
  },
  props: {
    editorAlias: {
      type: String,
      description: 'alias'
    },
    defaultCenter: {
      type: Object | String,
      description: 'map_center_coor',
      editor: 'MapCenterCoordinates',
      default: () => {
        return {
          coordinates_from_component: false,
          value: null
        }
      }
    },
    defaultZoom: {
      type: Number,
      description: 'map_def_zoom',
      default: 2,
      options: {
        isFloat: true
      }
    },
    declaredCoordinateSystemId: {
      type: Number,
      description: 'map_coor_system',
      hidden: true,
      default: 3857
    },
    layers: {
      type: Array,
      editor: 'MapLayers',
      description: 'map_layers'
    },
    layerFilters: {
      type: Array,
      editor: 'MapLayerFilters',
      description: 'map_layers_filter',
      default: () => []
    },
    toolsPanelWidth: {
      type: Number,
      description: 'map_tools_panel_width',
      default: 400
    },
    showLayersOpacity: {
      type: Boolean,
      description: 'map_show_layers_opacity',
      default: false
    },
    showLayersByDefault: {
      type: Boolean,
      description: 'map_show_layers_default',
      default: false
    },
    inscriptionStyle: {
      type: Object,
      description: 'map_inscription_style',
      editor: 'MapGeometryStyle',
      default: () => {
        return {
          polygon: null,
          label: null
        }
      },
      options: {
        buttonName: 'Настройка стиля для надписи'
      }
    },
    vertexNumberingStyle: {
      type: Object,
      description: 'map_vertex_numbering_style',
      editor: 'MapGeometryStyle',
      default: () => {
        return {
          point: null,
          label: null
        }
      },
      options: {
        buttonName: 'Настройка стиля для нумерации'
      }
    },
    defaultImportSettings: {
      type: Object,
      description: 'map_default_import_settings',
      editor: 'MapDefaultImportSettings',
      default: () => {
        return {
          epsg: null,
          need_to_convert_closed_line_to_polygon: false,
          swap_x_y_axes: false,
          swap_axes_before_transform: false,
          is_flattening_needed: false
        }
      }
    },
    expandLayersByDefault: {
      type: Boolean,
      description: 'map_expand_layer',
      default: false
    },
    tools: {
      type: Object,
      description: 'map_tools',
      default: () => {
        return {}
      },
      editor: 'MapTools'
    },
    coordinateSystems: {
      type: Object,
      description: 'map_coor_sytems',
      default: () => {
        return { items: [] }
      },
      editor: 'MapCoordinateSystems'
    },
    mobileMode: {
      type: Boolean,
      description: 'map_mobile',
      default: false
    },
    showMouseCoordinates: {
      type: Boolean,
      description: 'map_mouse_coor',
      default: false
    },
    hideToolsPanelAfterExpand: {
      type: Boolean,
      description: 'map_hide_tools',
      default: false
    },
    showScalelineControl: {
      type: Boolean,
      description: 'Показывать численный масштаб',
      default: false
    },
    showScaleControl: {
      type: Boolean,
      description: 'Разрешить задавать масштаб в формате 1:X',
      default: false
    },
    restrictExport: {
      type: Boolean,
      description: 'Запретить экспорт',
      default: false
    },
    baseLayerUseProxy: {
      type: Boolean,
      description: 'map_layers_proxy',
      default: false
    },
    hideOSMLayer: {
      type: Boolean,
      description: 'map_osm_layers',
      default: false
    },
    enableShowGeometrySettings: {
      type: Boolean,
      description: 'map_enabled_geo',
      default: true
    },
    toolsPosition: {
      editor: 'Select',
      description: 'map_tools_pos',
      default: 'tr',
      options: {
        multiple: false,
        options: [
          { id: 'tr', name: 'Сверху справа' },
          { id: 'tl', name: 'Сверху слева' },
          { id: 'br', name: 'Снизу справа' },
          { id: 'bl', name: 'Снизу слева' }
        ]
      }
    },
    activeInteractiveByDefault: {
      editor: 'Select',
      description: 'map_active_inter',
      default: INTERACTION_MODES.SELECT.POINT,
      options: {
        multiple: false,
        clearable: true,
        options: INTERACTION_MODES_OPTIONS
      }
    },
    isSelectInteractionPin: {
      type: Boolean,
      description: 'map_interaction_pin',
      default: true
    },
    featureMetrics: {
      type: Object,
      description: 'map_metric',
      default: function () {
        return {
          units: 'meters',
          enable: false,
          enableSwitch: false,
          numbersAfterDot: 2
        }
      },
      editor: 'FeatureMetrics'
    },
    layerContextMenuSettings: {
      type: Array,
      description: 'map_layer_context_menu_settings',
      default: function () {
        return [
          {
            name: 'calculate_features',
            hidden: false
          },
          {
            name: 'focus_on_layer',
            hidden: false
          },
          {
            name: 'export_layer',
            hidden: this.restrictExportData
          }
        ]
      },
      editor: 'MapLayerContextMenuSettings'
    },
    synchronizeControl: {
      type: Array | String,
      description: 'map_synchronize_control',
      editor: 'MapSynchronizeControl',
      default: null
    },
    showZoomControl: {
      type: Boolean,
      description: 'show_zoom_control',
      default: true
    },
    enableHotKeyCopyPaste: {
      type: Boolean,
      description: 'enable_hotkey_copy_paste',
      default: true
    },
    isInteractiveWithActiveLayerOnly: {
      type: Boolean,
      description: 'is_interactive_with_active_layer_only',
      default: false
    },
    buttonResetVTCacheLayers: {
      editor: 'ButtonResetVTCacheLayers',
      default: null,
    }
  },
  data () {
    return {
      guid: null,
      map: undefined,
      showToolsPanel: false,
      activeLayers: [],
      isGoogleStreetViewActive: false,
      googlePanorama: null,
      google: null,
      googleMapsCoordinateSystemId: 4326,
      isExpanded: false,
      expandedSettings: {
        notExpandedStyle: null,
        containers: [],
        dashboard: { dom: null, defaultWidth: null },
        tabSelectionHeight: 0,
        vueDraggableBlocks: [],
        tab: null,
        v2: {}
      },
      resizeObserver: false,
      showCardsPanel: false,
      cards: [],
      isLoading: false,
      position: {
        coordinates: {
          x: 0,
          y: 0
        },
        address: '',
        show_marker: true
      },
      activeFeatures: [],
      selectedObjects: [],
      showCadastrPanel: false,
      cadastrInfo: {
        area: {},
        building: {},
        zouit: {}
      },
      isLoadingToolsPanel: false,
      footerData: {
        coordinates: [0, 0],
        targetCS: this.declaredCoordinateSystemId
      },
      currentInteraction: null,
      toolsCheckMarks: {},
      isEditVerticesActive: false,
      userCSs: [],
      activeInteractiveType: [],
      paintBindingRadius: { enable: false, radius: 0 },
      zoomValue: this.defaultZoom,
      snapMode: false,
      fieldSourceLayers: {},
      importLayerGeometryField: null,
      importDefaultImportSettings: null,
      intersection: {
        showWindow: false,
        message: '',
        layers: []
      },
      componentSize: {
        width: null,
        height: null,
        toolsPanelWidth: this.realToolsPanelWidth
      },
      realToolsPanelWidth: this.toolsPanelWidth,
      positionOnAddressSettings: { addressPrefix: '', valueByDefault: '' },
      createGeometryByVertices: {
        layer: null
      },
      separatedLayers: {},
      hideButCheckedLayerGuids: [],
      hideLayerList: [],
      activeLayerGuid: null,
      addedProjections: [],
      interactiveCallback: null,
      pointIconCache: new Map(),
      vectorTileActiveFeatures: [],
      webglActiveFeatures: [],
      restrictExportData: this.restrictExport,
      syncControl: [],
      isInteractiveModeSelectedByUser: false,
      importedToVTLayers: [],
      tabIndex: this.enableHotKeyCopyPaste ? '0' : undefined,
      currentInteractiveOnMap: null,
      drawnPolygonInSelectionMode: null,
      pixelOfLastClick: null
    }
  },
  computed: {
    allActiveFeatures () {
      return [...this.activeFeatures, ...this.vectorTileActiveFeatures, ...this.webglActiveFeatures]
    },
    model () {
      return this.getModel()
    },
    streetViewContainerClass () {
      if (this.isGoogleStreetViewActive) {
        return 'streetViewContainer'
      }
      return 'streetViewContainerCollapsed'
    },
    mapClass () {
      if (this.isGoogleStreetViewActive) {
        return 'mapCollapsed'
      }
      return 'map'
    },
    toolsTrStyle () {
      if (this.isEditor() && this.toolsPosition === 'tr') {
        return 'top: 30px;'
      }
      return ''
    },
    activeLayersLength () {
      return this.activeLayers.length
    },
    inCard () {
      return typeof this.getCard === 'function'
    }
  },
  provide () {
    return {
      dashboardAndUserCSs: () => { return this.userCSs },
      changeElDialogFocus: (value) => { Utils.IgnoreUtilFocusChanges = value }
    }
  },
  watch: {
    model: {
      handler: async function () {
        this.handleLayerVisibleAndFilter()
      },
      deep: true
    },
    currentInteraction: {
      handler: function (newValue, oldValue) {
        this.removeInteraction(oldValue)
        this.applyInteraction(newValue)
      }
    },
    activeLayerGuid: {
      handler: async function (newValue, oldValue) {
        this.removeInteraction(this.currentInteraction)
        this.applyInteraction(this.currentInteraction, false)
      }
    }
  },
  beforeMount () {
    this.guid = this.generateGuid()
  },
  destroyed () {
    if (this.importedToVTLayers.length === 0) {
      return
    }
    for (let i = 0; i < this.importedToVTLayers.length; i++) {
      APIClient.shared.request(
        new MapEditorAPI.ResetImportVTLayer(this.importedToVTLayers[i].uploadId)
      )
    }
  },
  async mounted () {
    if (!this.isEditor()) {
      // this.layers = this.actualizeLayersTreeStructure(this.layers)
      for (let i = 0; i < this.layers.length; i++) {
        // this.layers.splice(i, 1, this.separateLayers(this.layers[i]))
      }
      this.layers = this.actualizeLayersTreeStructure(this.layers)
    }
    const { x, y, center_by_coordinates } = this.getDefaultCenter()
    let optsMap = {
      base_layer: 'osm',
      declared_coordinate_system_id: this.declaredCoordinateSystemId,
      zoom: this.defaultZoom,
      controls: []
    }
    if (center_by_coordinates) {
      optsMap.center = {
        x: x,
        y: y,
        declared_coordinate_system_id: 4326
      }
    }
    if (this.hideOSMLayer) {
      optsMap.base_layer = null
    }
    if (this.baseLayerUseProxy) {
      optsMap.base_layer_use_proxy = true
    }
    if (this.showScalelineControl) {
      optsMap.controls.push({ name: 'scaleline', className: 'olscaleline' })
    }
    if (this.showScaleControl && !this.mobileMode) {
      optsMap.controls.push(
        {
          name: 'scale',
          scales: ['1 : 100', '1 : 500', '1 : 1 000', '1 : 10 000', '1 : 100 000'],
          className: 'olscale'
        })
    }
    this.map = MapManager.createMap(this.guid, optsMap)

    if (!center_by_coordinates) {
      await this.centerByGeoJSON(this.map, this.defaultCenter)
    }

    this.showToolsPanel = this.showLayersByDefault
    this.setComponentSize()
    await this.$nextTick()
    const me = this
    this.repaintMap()

    this.syncControl = Array.isArray(this.synchronizeControl) ? this.synchronizeControl : (this.synchronizeControl ? [this.synchronizeControl] : [])

    if (!this.isEditor()) {
      this.applyShowMouseCoordinatesInteractive(
        this.showMouseCoordinates,
        this.syncControl
      )
    }
    await this.fillMouseCoordinates(this.$store.getters['Authorization/userId'], this.coordinateSystems.items)
    if (!this.isEditor()) {
      this.currentInteraction = this.activeInteractiveByDefault
    }
    await this.handleLayerVisibleAndFilter()
    // show layer by default
    this.showDefaultLayers(this.layers, this.hideLayerList)
    // active layer by default
    this.handleActiveLayerByDefault()

    let observer = new IntersectionObserver((entries, observer) => {
      this.repaintMap()
    })
    observer.observe(this.$el)

    MapManager.setZoomCallback(this.map, function (zoom, coordinates) { me.setZoomValue(zoom) })
    if (!this.isEditor()) {
      this.handleSynchronizeControl(this.syncControl)
    }
    this.map.map.on('click', this.onMapClickCallback)
  },
  methods: {
    getSlaveMap (guid) {
      let anotherMap = null
      if (guid) {
        const keyName = `component_${guid}`
        anotherMap = this.getDashboardComponents()[keyName]
        if (anotherMap) {
          anotherMap = anotherMap[0]
        }

        if (!anotherMap) {
          console.warn('component map for synchronize not found')
          return
        }
      }
      return anotherMap
    },
    getSlaveMaps (guids) {
      let result = []
      guids.forEach(guid => {
        const anotherMap = this.getSlaveMap(guid)
        if (anotherMap) {
          result.push(anotherMap)
        }
      })
      return result
    },
    leaveMasterMap () {
      if (this.isEditor()) {
        return
      }
      this.getSlaveMaps(this.syncControl).forEach(slaveMap => {
        slaveMap.clearCenterMarker()
      })
    },
    handleSynchronizeControl (guids) {
      if (!guids || guids.length === 0 || this.isEditor()) {
        return
      }
      const anotherMaps = this.getSlaveMaps(guids)
      if (!anotherMaps || anotherMaps.length === 0) {
        return
      }
      const me = this

      // handle change bbox
      MapManager.setZoomCallback(this.map, function (zoom, coordinates) {
        anotherMaps.forEach(anotherMap => {
          anotherMap.setCenterWithZoom(
            coordinates[0],
            coordinates[1],
            zoom,
            me.declaredCoordinateSystemId
          )
        })
      })
      const center = this.getMapCenterAndZoom()
      anotherMaps.forEach(anotherMap => {
        anotherMap.setCenterWithZoom(
          center.coordinates.x,
          center.coordinates.y,
          center.zoom,
          me.declaredCoordinateSystemId
        )
      })
      // handle show mouse
    },
    clearCenterMarker () {
      MapManager.clearCenterMarkers(this.map)
    },
    showMasterCursor (coordinate) {
      MapManager.clearCenterMarkers(this.map)
      MapManager.showMarker(this.map, coordinate, '/icon/differentIcons/map-component/cursor.png', ['top', 'left'])
    },
    getMapCenterAndZoom () {
      const view = this.map.map.getView()
      return {
        coordinates: {
          x: view.targetCenter_[0],
          y: view.targetCenter_[1]
        },
        zoom: view.getZoom()
      }
    },
    setZoomValue (zoom) {
      this.zoomValue = Math.round(zoom * 10) / 10
    },
    async centerByGeoJSON (map, centerSettings) {
      if (!centerSettings.coordinates_from_component) {
        return
      }
      const geoJson = this.getModel()[centerSettings.value]
      if (!geoJson) {
        return
      }
      const layer = MapManager.createLayerFromGeoJSON(
        geoJson,
        SourceType.Vector,
        {
          srs_handling: {
            native_coordinate_system_id: 4326,
            declared_coordinate_system_id: 4326,
            srs_handling_type: 'forced_declared'
          }
        }
      )
      MapManager.addLayer(this.map, layer)
      await this.fitWithDefaultZoom(layer)
      MapManager.removeLayer(map, layer)
    },
    clearActiveLayerField () {
      const activeLayer = this.getActiveLayer(true)
      if (activeLayer === false) {
        return
      }
      // удаляем значение в поле
      this.getModel()[activeLayer.layerData.source.geometryField] = null
      // очищаем слой
      MapManager.removeFeatures(this.map, MapManager.getFeatures(activeLayer.layer))
    },
    async importFeaturesByFilenamesOnVTLayer (files, showMessage) {
      const al = this.getActiveLayer(true)
      const geometryField = al.layerData.source.geometryField
      console.log(al)
      const filesForImport = files.map(x => { return { name: x.filename, epsg: x.srid, unit_of_measurement: x.unit_of_measurement } })
      await APIClient.shared.request(
        new DotNetSpatialServiceAPI.ImportFilesOnVTLayer({
          files: filesForImport,
          geom_attr_id: parseInt(geometryField),
          need_to_convert_closed_line_to_polygon: false,
          swap_axes_before_transform: false,
          swap_x_y_axes: false,
          is_flattening_needed: true,
          delete_file: false,
          load_invalid_geometry: true,
          layer_guid: al.guid,
          record_id: this.getModel().id
        })
      ).then(async (response) => {
        this.importedToVTLayers.push(
          {
            field: geometryField,
            layerGuid: al.guid,
            uploadId: response.uploadId
          }
        )
        // выключаем/включаем слой чтоб отобразились изменения
        this.hideLayer(al.guid)
        await this.showLayer(
          al.guid,
          {
            name: al.name,
            properties: al.layerData
          }
        )

        if (showMessage === true) {
          this.$message({
            message: `Было добавлено ${response.count} геометрий`,
            type: 'success'
          })
        }
      }).catch((err) => console.log(err))
    },
    async importFeaturesByFilenames (files, replaceFeatures, showMessage) {
      const al = this.getActiveLayer(true)
      const str = al.layerData.source.geometryField
      const geometryField = str.substr(str.indexOf('_') + 1, str.length - str.indexOf('_') - 2)
      if (replaceFeatures) {
        MapManager.removeFeatures(this.map, MapManager.getFeatures(al.layer))
      }
      let totalFeatures = 0
      for (let i = 0; i < files.length; i++) {
        const file = files[i]
        if (file.loaded) {
          await APIClient.shared.request(
            new DotNetSpatialServiceAPI.ConvertFromExistingFile({
              file_name: file.filename,
              epsg: file.srid,
              geom_attr_id: parseInt(geometryField),
              need_to_convert_closed_line_to_polygon: false,
              swap_x_y_axes: false,
              is_flattening_needed: true,
              delete_file: false,
              load_invalid_geometry: true
            }, true)
          ).then(async (response) => {
            totalFeatures += await this.importGeoJSONFeatures(response, undefined, true, false)
          }).catch((err) => console.log(err))
        } else {
          await APIClient.shared.request(
            new DotNetSpatialServiceAPI.ConvertFromDxfFile({
              file: file.raw,
              epsg: file.srid,
              geom_attr_id: parseInt(geometryField),
              need_to_convert_closed_line_to_polygon: false,
              swap_x_y_axes: false,
              is_flattening_needed: true,
              delete_file: false,
              load_invalid_geometry: true
            }, true)
          ).then(async (response) => {
            totalFeatures += await this.importGeoJSONFeatures(response, undefined, true, false)
          }).catch((err) => console.log(err))
        }
      }
      this.fitWithDefaultZoom(al.layer)
      if (showMessage === true) {
        this.$message({
          message: `Было добавлено ${totalFeatures} геометрий`,
          type: 'success'
        })
      }
    },
    layerSeparated (layerGuid, separatedGuids) {
      if (!this.separatedLayers.hasOwnProperty(layerGuid)) {
        this.separatedLayers[layerGuid] = separatedGuids
      }
    },
    setActiveInteractive (interactiveType, callback) {
      this.interactiveCallback = callback
      this.currentInteraction = interactiveType
    },
    actualizeLayersTreeStructure (treeItems) {
      treeItems.forEach((treeItem) => {
        if (treeItem.type === 'group') {
          let children = treeItem.children
          treeItem = this.actualizeLayerStructure(treeItem, DEFAULT_NODES['group'])
          if (treeItem.allowExpandAllChildren === false) {
            this.$set(treeItem, 'disabled', true)
          }
          treeItem.children = this.actualizeLayersTreeStructure(children)
        } else if (treeItem.type === 'layer') {
          treeItem = this.actualizeLayerStructure(treeItem, DEFAULT_NODES['layer'])
          if (treeItem.properties.source.filterType === 'simple' && treeItem.properties.source.filters.length === 0) {
            treeItem.properties.source.filterType = null
          }
        }
      })
      return treeItems
    },
    actualizeLayerStructure (layerItem, neededStructure) {
      for (const [neededKey, neededValue] of Object.entries(neededStructure)) {
        if (layerItem[neededKey] === undefined) {
          this.$set(layerItem, neededKey, neededValue)
        }
        if (typeof neededValue === 'object' && !Array.isArray(neededValue) && neededValue !== null) {
          layerItem[neededKey] = this.actualizeLayerStructure(layerItem[neededKey], neededValue)
        }
      }
      return layerItem
    },
    clickOnLayerOnTree (layerGuid) {
      const layer = this.activeLayers.find((layer) => layer.guid === layerGuid)
      if (!layer) {
        console.log('layer is not enabled')
        return
      }
      if (this.activeLayerGuid === layerGuid) {
        this.activeLayerGuid = null
      } else {
        this.activeLayerGuid = layerGuid
        this.restrictExportData = layer.data.properties.restrictExport
      }
    },
    handleActiveLayerByDefault () {
      const activeLayers = this.getActiveLayersByDefault(this.layers, [])
      const activeAndVisibleLayers = activeLayers.filter((item) => !this.hideLayerList.includes(item.guid), this)
      if (activeAndVisibleLayers.length === 1) {
        this.activeLayerGuid = activeAndVisibleLayers[0].guid
      } if (activeAndVisibleLayers.length > 1) {
        console.log('active layer should be one')
      }
    },
    async handleLayerVisibleAndFilter () {
      const activeLayersAtCurrentTime = this.activeLayers.map((item) => {
        return {
          filters: item.filters,
          lastFilters: item.lastFilters,
          guid: item.guid,
          layerData: item.layerData,
          name: item.name,
          dynamicFilters: item.dynamicFilters,
          lastDynamicFilters: item.lastDynamicFilters
        }
      })
      this.hideLayerList = this.getLayersToHide()
      // был скрыт, но стал виден
      let removeHBUGuids = []
      for (let i = 0; i < this.hideButCheckedLayerGuids.length; i++) {
        const layer = this.hideButCheckedLayerGuids[i]
        if (!this.hideLayerList.includes(layer.guid)) {
          await this.showLayer(layer.guid, { name: layer.name, properties: layer.layerData }, layer.dynamicFilters, false)
          this.changeLayerOpacity(layer, layer.layerData.opacity)
          removeHBUGuids.push(layer.guid)
        }
      }
      removeHBUGuids.forEach(guid => {
        this.hideButCheckedLayerGuids.splice(this.hideButCheckedLayerGuids.findIndex(item => item.guid === guid), 1)
      }, this)
      // пересчитываем фильтры
      for (let i = 0; i < activeLayersAtCurrentTime.length; i++) {
        const item = activeLayersAtCurrentTime[i]
        const newFilters = this.buildLayerFilters(item.layerData)

        if (
          !this.hideLayerList.includes(item.guid) && // не скрыто
            (JSON.stringify(newFilters) !== JSON.stringify(item.lastFilters)) // и фильтр изменился)
        ) {
          this.hideLayer(item.guid)
          await this.showLayer(
            item.guid,
            {
              name: item.name,
              properties: item.layerData
            }
            //, item.dynamicFilters
          )

          const layer = this.activeLayers.find(x => x.guid === item.guid)
          this.changeLayerOpacity(layer, item.layerData.opacity)
        }
      }

      // слой был виден, но стал скрыт
      activeLayersAtCurrentTime.forEach((activeLayer) => {
        if (this.hideLayerList.includes(activeLayer.guid)) {
          this.hideButCheckedLayerGuids.push(activeLayer)
          this.hideLayer(activeLayer.guid)
        }
      })
    },
    showDefaultLayers (layers, hideLayers) {
      this.getDefaultLayers(layers, hideLayers).forEach((guidToHide) => {
        this.$refs.tools_panel.setCheckOnLayer(guidToHide, true)
      })
    },
    getDefaultLayers (array = [], hideLayers) {
      let result = []
      array.forEach((item) => {
        if (item.properties.showByDefault && !hideLayers.includes(item.guid)) {
          result.push(item.guid)
        }
        if ((item.children || []).length > 0) {
          result.push(...this.getDefaultLayers(item.children, hideLayers))
        }
      })

      return result
    },
    getActiveLayersByDefault (array = [], activeLayers) {
      let result = []
      array.forEach((item) => {
        if (item.properties.isActiveByDefault && !activeLayers.includes(item.guid)) {
          result.push(item)
        }
        if ((item.children || []).length > 0) {
          result.push(...this.getActiveLayersByDefault(item.children, activeLayers))
        }
      })
      return result
    },
    getLayersToHide () {
      if (this.isEditor()) {
        return []
      }
      let fittedFilter = null
      // группы условий
      const model = this.getModel()
      if (this.layerFilters.length > 0 && Object.keys(model).length === 0) {
        return []
      }
      this.layerFilters.forEach((layerFilter) => {
        if (this.checkConditions(layerFilter.filter)) {
          // фильтр подходит
          if (fittedFilter) {
            fittedFilter.layerGuids.push(...layerFilter.layerGuids)
          } else {
            fittedFilter = layerFilter
          }
        }
      }, this)
      // здесь все хорошо, но не учитываются разбитые по условию слои, т.к. каждое разбитие это новый слой
      if (fittedFilter && Array.isArray(fittedFilter.layerGuids)) {
        let additionalGuids = []
        fittedFilter.layerGuids.forEach((guid) => {
          if (this.separatedLayers.hasOwnProperty(guid)) {
            additionalGuids.push(...this.separatedLayers[guid])
          }
        })
        fittedFilter.layerGuids.push(...new Set(additionalGuids))
      }

      return fittedFilter ? fittedFilter.layerGuids : []
    },
    async pasteGeoJSONInActiveLayer () {
      // обрабочик ctrl+v
      const gjson = await navigator.clipboard.readText().then((text) => {
        return text
      })

      const activeLayerData = this.getActiveLayer(true)
      /*
        this.$confirm(
          'Вы уверены что хотите вставить геометрию в слой "' + activeLayerData.name + '"'
        ).then(() => { */
      try {
        const fc = MapManager.createFeatureCollectionFromGeoJSON(
          gjson,
          activeLayerData.layerData.source.nativeCoordinateSystemId,
          this.declaredCoordinateSystemId
        )

        MapManager.addFeatures(this.map, activeLayerData.layer, fc)
        MapManager.fitFeatures(this.map, fc)
        this.getModel()[activeLayerData.layerData.source.geometryField] = this.getActiveLayerFeatures()
        this.$message({
          message: `Вставка геометрии прошла успешно`,
          type: 'success'
        })
      } catch (e) {
        console.log(e)
        this.showError('Не удалось вставить геометрию')
      }
      // })
    },
    copyActiveFeaturesGeoJSON () {
      // обработчик ctrl+c
      if (this.activeFeatures.length !== 1) {
        this.showError('Сейчас копировать можно только 1 выбранную геометрию')
        return
      }

      if (!this.activeFeatures[0].layerProperties.isCopyable) {
        this.showError(`Копирование геометрии из слоя "${this.activeFeatures[0].layerName}" запрещено`)
        return
      }

      // let result = ''
      const geojson = MapManager.getGeometryAsText(
        this.activeFeatures[0].item,
        GeometryFormat.GeoJSON,
        this.map.getSRSId(),
        this.activeFeatures[0].nativeCoordinateSystemId
      )

      this.$copyText(geojson)
      this.showSuccess('Геометрия скопирована')
      this.$refs['map'].focus()
    },
    copyGeometry (a) {
      const guid = a[1]
      const feature = a[0]
      const layer = this.activeLayers.find(l => l.guid === guid)
      if (!layer) {
        this.showError('Выбранный слой не включен')
        return
      }
      this.$confirm(
        'Вы уверены что хотите скопировать выбранную геометрию в "' + layer.name + '"', {
          confirmButtonText: 'Да',
          cancelButtonText: 'Нет'
        }).then((confirm) => {
        if (guid === feature.layerProperties.guid) {
          this.showError('Копирование геометрии внутри слоя невозможно')
          return
        }
        try {
          MapManager.addFeatures(this.map, layer.layer, new FeatureCollection([feature.feature.feature], layer.layer))
          this.refreshGeometryField('Успешно скопировано', true)
        } catch (e) {
          console.log(e)
        }
      })
    },
    repaintMap () {
      this.$nextTick(() => {
        setTimeout(() => MapManager.updateSize(this.map), 0)
      })
    },
    searchToolComponent (address) {
      this.positionOn({ x: address.data.geo_lon, y: address.data.geo_lat }, 4326)
    },
    getActiveLayerByGuid (guid) {
      return this.activeLayers.find(x => x.guid === guid)
    },
    focusOnLayer (layer) {
      // let mapLayer = this.activeLayers.find((item) => { return item.guid === layer.guid })
      this.fitWithDefaultZoom(this.getActiveLayerByGuid(layer.guid).layer)
      // MapManager.fitLayer(this.map, this.getActiveLayerByGuid(layer.guid).layer)
    },
    async calculateFeatures (layer) {
      let existingLayer = this.getActiveLayerByGuid(layer.guid)
      if (!existingLayer) {
        this.showError('Слой не включен')
        return
      }
      await MapManager.getFeatureCountTotal(existingLayer.layer)
        .then((result) => {
          this.showSuccess('У слоя "' + layer.name + '" найдено ' + result + ' контуров')
          this.$nextTick(() => {
            this.$set(layer, 'totalFeatures', result)
          })
        })
    },
    showHideLayersButton () {
      this.showToolsPanel = !this.showToolsPanel
      this.setComponentSize()
    },
    setComponentSize (newToolsPanelWidth) {
      if (newToolsPanelWidth) {
        this.$set(this, 'realToolsPanelWidth', newToolsPanelWidth)
      }
      this.$set(this, 'componentSize', {
        height: this.$el.clientHeight,
        width: this.$el.clientWidth,
        toolsPanelWidth: this.showToolsPanel ? this.realToolsPanelWidth : 0
      })
    },
    inputValidator (rule, value, callback) {
      if (this.importedToVTLayers.length > 0) {
        // сохранение карточки ждет поле целиком, а не его id
        this.getModel()['imported_geometry'] = this.importedToVTLayers.map(x => { return { ...x, field: `attr_${x.field}_` } })
      }

      let isValid = true
      let keys = Object.keys(this.fieldSourceLayers)
      for (let i = 0; i < keys.length; i++) {
        const item = this.fieldSourceLayers[keys[i]]
        if (item.validation !== 'error') {
          continue
        }
        if (this.getModel()[item.field]) {
          let fc = MapManager.createFeatureCollectionFromGeoJSON(
            this.getModel()[item.field],
            item.nativeCoordinateSystemId,
            item.declaredCoordinateSystemId
          )
          isValid = fc.getFeatures().every(x => MapManager.isValid(x))
        }
      }
      if (!isValid) {
        let me = this
        window.setTimeout(() => { me.showError('Невозможно сохранить невалидную геометрию') }, 500)
      }
      return !isValid ? callback('error') : callback()
    },
    applyDynamicFilter (layer, isNeedFitLayer) {
      if (this.hideLayerList.includes(layer.guid)) {
        return
      }
      this.hideLayer(layer.guid)
      this.showLayer(layer.guid, layer.data, layer.dynamicFilters, true, isNeedFitLayer)
    },
    async fillMouseCoordinates (userId, additionSrsIds) {
      this.userCSs.splice(0, this.userCSs.length)
      // dashboard cs
      if (Array.isArray(additionSrsIds)) {
        for (let i = 0; i < additionSrsIds.length; i++) {
          let newSystem = await APIClient.shared.request(new MapEditorAPI.GetCoordinateSystemBySrid(additionSrsIds[i]))
          // this.dashboardCSs.push(this.formatCS(newSystem[0]))
          const newSystemFormatted = this.formatCS(newSystem[0], true)
          this.userCSs.splice(this.userCSs.length, 0, newSystemFormatted)
          this.registerNewCS(newSystemFormatted)
        }
      }
      // user cs
      try {
        let userSystems = await APIClient.shared.request(new MapEditorAPI.GetCoordinateSystemsByUserId(userId))
        if (Array.isArray(userSystems)) {
          userSystems.forEach((item) => {
            if (this.userCSs.findIndex((item1) => { return item1.auth_srid === item.auth_srid }) === -1) {
              this.userCSs.splice(this.userCSs.length, 0, this.formatCS(item, false))
              this.registerNewCS(item)
            }
          }, this)
        }
      } catch (e) { }
    },
    formatCS (newCS, fromDashboard) {
      if (fromDashboard !== true) {
        fromDashboard = false
      }
      const code = 'EPSG:' + newCS.auth_srid
      const proj4text = newCS.proj4text
      let label = ''
      if (newCS.description) {
        label = newCS.description
      } else if (newCS.auth_name !== 'EPSG') {
        label = newCS.auth_name
      } else if (proj4text) {
        let aa = proj4text.indexOf('+title=')
        if (aa !== -1) {
          aa += 7
          let bb = proj4text.indexOf(' +', aa)
          if (bb === -1) {
            bb = proj4text.length
          }
          label = proj4text.substring(aa, bb)
        }
      }
      return {
        label: '(' + code + ') ' + label,
        value: newCS.auth_srid,
        srid: newCS.auth_srid,
        auth_name: newCS.auth_name,
        auth_srid: newCS.auth_srid,
        proj4text: newCS.proj4text,
        fromDashboard: fromDashboard
      }
    },
    async registerNewCS (newCS) {
      const code = 'EPSG:' + newCS.srid
      const proj4text = newCS.proj4text

      if (code && proj4text) {
        MapManager.addProjection(code, proj4text)
      }
    },
    async loadAndRegisterCS (newCS) {
      if (this.addedProjections.includes(newCS)) {
        return
      }
      this.addedProjections.push(newCS)
      let system = await APIClient.shared.request(new MapEditorAPI.GetCoordinateSystemBySrid(newCS))
      await this.registerNewCS(system[0])
    },
    applyShowMouseCoordinatesInteractive (enable, synchronizeComponentGuids) {
      let enableSynchronizeControl = true

      if (!synchronizeComponentGuids || synchronizeComponentGuids.length === 0 || this.isEditor()) {
        enableSynchronizeControl = false
      }
      let anotherMaps = this.getSlaveMaps(synchronizeComponentGuids)

      if (!enable && !enableSynchronizeControl) {
        return
      }
      let me = this
      MapManager.setMapCoordinatesInteraction(this.map, {
        'type': EventType.PointerMove,
        'map_coordinates_callback': (coordinates, mapProjection) => {
          if (enable) {
            me.footerData.coordinates.splice(0, 1, coordinates[0])
            me.footerData.coordinates.splice(1, 1, coordinates[1])
          }
          if (enableSynchronizeControl && anotherMaps.length > 0) {
            anotherMaps.forEach(anotherMap => {
              anotherMap.showMasterCursor(coordinates)
            })
          }
        },
        'declared_coordinate_system_id': me.footerData.targetCS
      })
    },
    buildLayerFilters (layerSettings) {
      let result

      switch (layerSettings.source.filterType) {
        case 'complex':
          result = this.buildComplexFilters(
            [{
              query: layerSettings.source.complexFilters.rule,
              type: 'condition_group'
            }]
          )
          break
          // case 'state': break
        case 'simple':
          result = this.buildLayerSimpleFilters(layerSettings.source.filters)
          break
      }
      // добавляю фильтры для деления слоя
      if (layerSettings.separation.type === 'rules' &&
          layerSettings.separation.enable === true &&
          (layerSettings.separation.rule.length !== 0 &&
            layerSettings.separation.rule.query !== null)
      ) {
        if (layerSettings.source.filterType === 'complex') {
          if (result) {
            result = { and: [result, this.buildComplexFilters([{ query: layerSettings.separation.rule.rule, type: 'condition_group' }])] }
          } else {
            result = this.buildComplexFilters([{ query: layerSettings.separation.rule.rule, type: 'condition_group' }])
          }
        }
        if (layerSettings.source.filterType === 'simple' || layerSettings.source.filterType === null) {
          if (result && result.where && result.where.and) {
            // TODO dashbpoard components value
            result.where.and.push(this.buildComplexFilters([{ query: layerSettings.separation.rule.rule, type: 'condition_group' }]))
          } else {
            result = { where: this.buildComplexFilters([{ query: layerSettings.separation.rule.rule, type: 'condition_group' }]) }
          }
        }
      }

      return this.changeAPIQlkeys(result)
    },
    changeAPIQlkeys (object) {
      if (!object) {
        return object
      }
      const keys = [
        { key: 'contains_string', target: 'like' },
        { key: 'not_contains_string', target: 'not_like' }
      ]
      let result = Array.isArray(object) ? [] : {}
      for (let [key, value] of Object.entries(object)) {
        for (let i = 0; i < keys.length; i++) {
          if (key === keys[i].key) {
            value[Object.keys(value)[0]] = `%${value[Object.keys(value)[0]]}%`
            key = keys[i].target
          }
        }
        if (typeof value === 'object' && value) {
          result[key] = this.changeAPIQlkeys(value)
        } else if (Array.isArray(value)) {
          result[key] = []
          for (let i = 0; i < value.length; i++) {
            result[key][i] = this.changeAPIQlkeys(value[i])
          }
        } else {
          result[key] = value
        }
      }
      return result
    },
    buildLayerSimpleFilters (layerFilters) {
      const filters = new FilterBuilder(
        layerFilters,
        this.getModel(),
        this.$store,
        EComponentTypes.registry
      ).buildAsApiQl()

      if (Array.isArray(filters) && filters.length === 0) {
        return undefined
      }

      return { where: { and: filters } }
    },
    //! !!!!!!!
    buildComplexFilters (conditions = [], level = 0) {
      let query = level === 0 ? {} : []

      for (let i = 0; i < conditions.length; i++) {
        const condition = conditions[i]

        if (condition.type === 'condition_group') {
          if (Array.isArray(query)) {
            let group = {}
            this.$set(group, condition.query.logical_operator, this.buildComplexFilters(condition.query.children, level + 1))

            query.push(group)
          } else {
            this.$set(query, condition.query.logical_operator, this.buildComplexFilters(condition.query.children, level + 1))
          }
        } else {
          const contains = { like: 'like', not_like: 'not_like' }
          let operator = condition.query.operator

          let parent = {}
          let child = {}

          // при фильтрации мн. ссылки не работают с in и not_in
          if (operator === 'in' && condition.query.field_type === 'xref_multi_field') {
            operator = 'equals_any'
          } else if (operator === 'not_in' && condition.query.field_type === 'xref_multi_field') {
            operator = 'not_equals_any'
          }

          const value = condition.query.filter_type === 'component' ? this.model[condition.query.value] : condition.query.value
          // если значение пустое - apiql не примет eq attr_N_ = null, надо заменить на attr_N_ is_null
          if (!['is_null', 'is_not_null'].includes(operator) && (value === null || value === undefined)) {
            operator = 'is_null'
          }
          // if (condition.query.filter_type === 'component') {}
          if (['is_null', 'is_not_null'].indexOf(operator) !== -1) {
            this.$set(parent, operator, condition.query.field)
          } else if (['like', 'not_like'].indexOf(operator) !== -1) {
            this.$set(child, condition.query.field, '%' + value + '%')
            // this.$set(child, condition.query.field, value)
            this.$set(parent, contains[operator], child)
          } else {
            this.$set(child, condition.query.field, value)
            this.$set(parent, operator, child)
          }

          query.push(parent)
        }
      }

      return query
    },
    actualizeInteractiveStructure (layer) {
      if (layer.properties.interactive.card) {
        if (!layer.properties.interactive.card.card) {
          layer.properties.interactive.card.card = {}
          if (Object.keys(layer.properties.interactive.card.card).length === 0) {
            let tmp = JSON.parse(JSON.stringify(layer.properties.interactive.card))
            delete tmp.card
            layer.properties.interactive.card.card = tmp
            layer.properties.interactive.card.type = 'open_card'
          }
        }
      }
      return layer
    },
    separateLayers (item) {
      if (item.type === 'group') {
        item.children = item.children.map(child => this.separateLayers(child))
      } else if (item.type === 'layer') {
        item = this.actualizeInteractiveStructure(item)
        if (
          item.properties.separation.type === 'rules' &&
            item.properties.separation.rules.length > 0 &&
            item.properties.separation.enable !== false) {
          // item = this.separateLayer(item)
          item = {
            guid: item.guid,
            isVisible: true,
            leaf: false,
            name: item.name,
            properties: { sourceLayer: item },
            type: 'group',
            children: []
          }
        }
      }
      return item
    },
    separateLayer (layer) {
      if (layer.properties.separation.type !== 'rules' || layer.properties.separation.rules.length === 0) {
        return layer
      }
      let group = {
        guid: layer.guid,
        isVisible: true,
        leaf: layer.leaf, // ?
        name: layer.name,
        properties: {},
        type: 'group',
        children: []
      }
      if (layer.properties.separation.allowExpandAllChildren === false) {
        group.disabled = true
      }
      this.separatedLayers[layer.guid] = []
      layer.properties.separation.rules.forEach((rule) => {
        const guid = this.generateGuid()
        this.separatedLayers[layer.guid].push(guid)
        let newLayer = JSON.parse(JSON.stringify(layer))
        newLayer.properties.separation.rule = rule
        newLayer.guid = guid
        newLayer.name = rule.name
        newLayer.properties.style = rule.style
        group.children.push(newLayer)
      })
      return group
    },
    onLayerSelect (data, value) {
      if (this.isEditor()) {
        return false
      }
      value ? this.showLayers(data) : this.hideLayers(data)
    },
    showLayers (data) {
      if (data.type === NodeLayerType.GROUP) {

      } else if (data.type === NodeLayerType.LAYER) {
        this.showLayer(data.guid, data)
        this.$set(data, 'isVisible', true)
      }
    },
    hideLayers (data) {
      if (data.type === NodeLayerType.GROUP) {

      } else if (data.type === NodeLayerType.LAYER) {
        this.hideLayer(data.guid)
        this.$set(data, 'isVisible', false)
      }
    },
    hideLayer (guid) {
      const layer = this.activeLayers.find((item) => item.guid === guid)
      if (!layer) {
        console.error(`layer not found, guid = ${guid}`)
        return false
      }
      layer.zIndexWatcher()
      // displayedLayers
      if (layer.layer === null && Array.isArray(layer.displayedLayers)) {
        layer.displayedLayers.forEach(displayedLayer => {
          MapManager.removeLayer(this.map, displayedLayer)
        }, this)
      } else {
        MapManager.removeLayer(this.map, layer.layer)
        if (layer.layerData.source.type === 'Registry' && layer.layerData.source.isVectorTile) {
          this.vectorTileActiveFeatures = []
        }
        if (layer.layerData.source.type === 'Registry' && layer.layerData.source.isVectorTile && layer.layerData.source.isWebGLVectorTile) {
          this.webglActiveFeatures = []
        }
      }
      const indexToDel = this.activeLayers.findIndex((item) => item.guid === guid)
      this.activeLayers.splice(indexToDel, 1)
      this.reloadInteraction()
    },
    addOuterXrefListeners () {
      const me = this
      window.setTimeout(function () {
        me.outerXrefTables.push(...Object.values(me.getDashboardComponents())
          .filter(x => {
            if (x[0].outerXrefId) {
              me.outerXrefIds.push(x[0].outerXrefId)
              me.$on('')
              return true
            }
            return false
          })
          .map(x => x[0])
        )
      }, 2000)
    },
    async showLayer (guid, data, dynamicFilters, isByDynamicFilters, isNeedFitLayer) {
      let showDynamicFilterSuccessMessage = isByDynamicFilters === true
      const layerData = data.properties
      await this.loadAndRegisterCS(layerData.source.nativeCoordinateSystemId)

      let layer
      let filters
      let style = {}
      const defaultStyles = MapManager.getDefaultStyles()
      Object.keys(defaultStyles).forEach((type) => {
        if (layerData.style.hasOwnProperty(type) && (layerData.style[type] || []).length > 0) {
          style[type] = layerData.style[type].reduce((obj, item) => {
            obj[item.name] = item.value
            if (item.name === 'icon_file' && item.value.hasOwnProperty('guid')) {
              obj['image_path'] = `/files/mapeditor/images/${item.value.guid}.${item.value.extension}`
            }
            return obj
          }, {})
        } else {
          style[type] = JSON.parse(JSON.stringify(defaultStyles[type]))
        }
      })
      if (layerData.hasOwnProperty('paint')) {
        if (layerData.paint.type === 'unique_value') {
          let field = '' + layerData.paint.field
          if (layerData.source.type === 'Registry') {
            if (field.search(new RegExp('^attr_[0-9]+_', 'g')) === -1) {
              field = 'attr_' + layerData.paint.field + '_'
            }
          }
          style.unique_values = {
            field: field,
            start_color: layerData.paint.startColor
          }
          if (layerData.paint.hasOwnProperty('incrementColor') && layerData.paint.incrementColor != null) {
            style.unique_values.increment_color = layerData.paint.incrementColor
          }
        }
      }

      if (layerData.style.hasOwnProperty('featureDisplayRules') && layerData.style.filterDisplayRulesOnBackend === false) {
        try {
          style['feature_hide_rules'] = JSON.parse(layerData.style.featureDisplayRules)
        } catch (e) {}
      }

      let fieldId = null

      // loading icon
      data.isLoading = true
      let params = {
        style: style,
        // properties can contains anything
        properties: {
          name: data.name
        },
        load_callback: async (layer) => {
          console.log('loaded layer')
          if (layerData.fit) {
            await this.fitWithDefaultZoom(layer)
          }
          this.$nextTick(() => this.changeLayerOpacity(data, data.properties.opacity))
          // loading icon
          this.$set(data, 'isLoading', false)
        }
      }
      if (layerData.interactive.hasOwnProperty('popup') && layerData.interactive.popup.text) {
        params.feature_popup_settings = {}
        params.feature_popup_settings.template = layerData.interactive.popup.text
        params.feature_popup_settings.css = layerData.interactive.popup.css
        const range = layerData.interactive.popup.range
        if (range) {
          params.feature_popup_settings.min_zoom = range[0]
          params.feature_popup_settings.max_zoom = range[1]
        }
      }
      if (layerData.hasOwnProperty('viewZoomRange') && Array.isArray(layerData.viewZoomRange) && layerData.viewZoomRange.length === 2) {
        params.min_zoom = layerData.viewZoomRange[0]
        params.max_zoom = layerData.viewZoomRange[1] === 20 ? 28 : layerData.viewZoomRange[1]
      }

      let additionalFields
      let fullAdditionalFields
      if (layerData.source.type === 'Registry' && layerData.source.isVectorTile) {
        additionalFields = ['id']
        if (layerData.source.marksField) {
          additionalFields.push(`attr_${layerData.source.marksField}_`)
        }
        fullAdditionalFields = this.getAdditionalFields(layerData)
      } else {
        additionalFields = this.getAdditionalFields(layerData)
      }
      if (layerData.style.enableCustomStyles) {
        additionalFields.push(`style_${layerData.source.geometryField}_`)
      }
      if (layerData.style.enableSelectStyles && style.select) {
        params.style.select = {
          point: {
            marker_type: 'simple_point',
            color: style.select.color,
            opacity: style.select.opacity,
            size: 3
          },
          linestring: {
            color: style.select.color,
            opacity: style.select.opacity,
            stroke_width: 3
          },
          polygon: {
            color: style.select.color,
            stroke_width: 3,
            opacity: style.select.opacity,
            background_color: style.select.background_color
          }
        }
      } else {
        params.style.select = null
      }

      let activeDynamicFilters = null
      // реально отображаемые слои (для реестровых слоев с геометрий из внешнего сервиса)
      let realLayers = []
      switch (layerData.source.type) {
        case 'Registry':
        case 'Query':
          fieldId = layerData.source.geometryField

          let url = null
          let extentBaseUrl = null
          if (layerData.source.type === 'Registry') {
            if (layerData.source.isVectorTile) {
              // включен импорт
              if (layerData.source.enableImport) {
                url = `${this.$config.api}/mapeditor/vectortiles/import/${layerData.source.entityId}/${fieldId}/{z}/{x}/{y}`
              } else {
                url = `${this.$config.api}/mapeditor/vectortiles/${layerData.source.entityId}/${fieldId}/{z}/{x}/{y}`
              }

              // params.url = url
              params.format = 'mvt'
              data.isLoading = false
              extentBaseUrl = `${this.$config.api}/mapeditor/geojson/registry/${layerData.source.entityId}`
            } else {
              url = `${this.$config.api}/mapeditor/geojson/registry/${layerData.source.entityId}`
            }
          } else if (layerData.source.type === 'Query') {
            url = `${this.$config.api}/mapeditor/geojson/query/${layerData.source.entityId}`
          }
          filters = this.buildLayerFilters(layerData)

          let totalDynamicFilters = 0
          // добавляю динамические фильтры
          if (dynamicFilters && Array.isArray(dynamicFilters)) {
            const df = this.buildDynamicFilters(dynamicFilters, layerData.source.type)
            if (df === false) {
              showDynamicFilterSuccessMessage = false
            }
            if (df !== null) {
              activeDynamicFilters = df
              totalDynamicFilters = df.and.length
              if (filters && filters.where && filters.where.and) {
                filters.where.and.push(df)
              } else {
                filters = { where: df }
              }
            }
          }

          if (layerData.style.enableCustomStyles) {
            params.style.style_in_feature_attribute = true
            if (layerData.source.isVectorTile && layerData.source.enableImport) {
              params.style.style_attribute = 'system_style'
            } else {
              params.style.style_attribute = `style_${fieldId}_`
            }
            params.style.point_icon_function = (url) => {
              if (!url) {
                return 'empty.png'
              }
              const basePath = `${this.$config.api}/files/mapeditor/images/`
              const arr = url.split('/')
              const guid = arr[arr.length - 1]
              return `${basePath}${guid}.png`
              /* let data = this.pointIconCache.get(guid)
                if (!data) {
                  data = await new PointStyleImageQueryRepository().getByGuid(guid)
                  this.pointIconCache.set(guid, data)
                }
                return `${basePath}${guid}.${data.extension}` */
            }
          }

          const data1 = {
            field: layerData.source.type === 'Registry' ? `attr_${layerData.source.geometryField}_geom` : layerData.source.geometryField,
            properties: additionalFields,
            constant_fields: [{ name: 'layer_guid', value: guid }],
            layer_guid: guid
          }
          if (filters) {
            data1.filter = filters
          }
          if (layerData.style.hasOwnProperty('featureDisplayRules') && layerData.style.filterDisplayRulesOnBackend === true) {
            try {
              data1.display_rules = JSON.parse(layerData.style.featureDisplayRules)
            } catch (e) {}
          }
          if (layerData.source.marksField) {
            params.style.label.field = `attr_${layerData.source.marksField}_`
          }
          params.srs_handling = {
            native_coordinate_system_id: layerData.source.nativeCoordinateSystemId,
            declared_coordinate_system_id: this.declaredCoordinateSystemId,
            srs_handling_type: 'keep_native' // layerData.source.srsHandlingType,
          }
          params.request = {
            method: HTTPMethod.POST,
            base_url: url,
            extent_base_url: extentBaseUrl,
            data: data1,
            headers: { },
            axios_params: {
              hideNotification: true
            }
          }
          if (layerData.source.isVectorTile) {
            params.request.headers = {
              'Authorization': localStorage.getItem('user-token')
            }
            params.request.data.cache_ttl = layerData.source.vectorTileCacheTTL
            params.request.data.extent = layerData.source.vectorTileExtent

            if (layerData.source.enableImport) {
              const upload = this.importedToVTLayers.find(x => x.layerGuid === guid)
              params.request.data.upload_id = upload?.uploadId || null
              /* params.request.data.layer_guid = guid
                let recordId = this.getModel().id
                if (!recordId) {
                  console.warn('record id not found', recordId)
                  recordId = 0
                  //return
                }
                params.request.data.record_id = recordId
                */
            }
          }
          if (layerData.source.filterType === 'state' && layerData.source.stateId) {
            params.request.params = {
              state_id: layerData.source.stateId
            }
            // params.state_id = layerData.source.stateId
          }
          let sourceType = SourceType.Vector
          if (layerData.source.isVectorTile) {
            sourceType = SourceType.VectorTile
            if (layerData.source.isWebGLVectorTile) {
              sourceType = SourceType.WebGLVectorTile
            }
          }
          if (params.style.point &&
              params.style.point.hasOwnProperty('cluster_distance') &&
              params.style.point.cluster_distance > 0) {
            sourceType = SourceType.Cluster
          }
          // в этом месте нужно смотреть включена ли галка enableExternalSourceFromField
          if (layerData.source.enableExternalSourceFromField) {
            // сделать запрос в registryservice, получить все записи удовлетворяющие фильтрам.
            // для каждой записи где поле с ссылкой не пустое создать новый слой (но не добавлять в layers)
            const attrName = `attr_${layerData.source.externalSourceField.field}_`
            // добавить проверку на "не пустую" ссылку
            const additionalRule = { is_not_null: attrName }
            let f = params.request.data.filter
            if (f === undefined) {
              f = {}
              f.and = [additionalRule]
            } else {
              if (f.where) {
                f = f.where
              }
              if (f.and) {
                f.and.push(additionalRule)
              } else if (f.or) {
                f = {
                  and: [
                    { 'or': [...f.or] },
                    additionalRule
                  ]
                }
              }
            }
            const props = {
              ...f,
              'optimize_options': attrName
            }
            try {
              const answer = await APIClient.shared.request(new DataAPI.GetData(layerData.source.entityId, props))
              layer = null
              answer.data.forEach((filteredItem) => {
                const p = {
                  request: {
                    method: HTTPMethod.GET,
                    base_url: filteredItem[attrName]
                  },
                  load_callback: async () => {
                    data.isLoading = false
                  }
                }
                const l = MapManager.createLayer(SourceType.TileWMS, p)
                // сделать выключение слоев, проверить фильтры + динамические
                realLayers.push(l)
              }, this)
            } catch (e) {
              console.log(e)
            }
          } else {
            layer = MapManager.createLayer(sourceType, params, this.map)
            // if (sourceType === SourceType.WebGLVectorTile) {
              // MapManager.setLayerFeatureClickCallback(layer, async (info, e) => {
              //   if (this.currentInteraction === INTERACTION_MODES.SELECT.POLYGON) {
              //     // При клике точки в режиме рисования полигона происходит постоянное обновление в карточке свойств
              //     return
              //   }
              //   if (!this.isSelectInteractionPin) {
              //     MapManager.clearSelection(this.map)
              //   }
              //   let webGLVectorTileLayer = this.activeLayers.find((l) => info.object.properties.layer_guid === l.guid)
              //   // webGLVectorTileLayer.layer.setHighlightFeatureId(info.object.properties.id)
              //   webGLVectorTileLayer.layer.setSelectedObjects([info.object.properties.id])
              //
              //   const x = e.offsetCenter.x
              //   const y = e.offsetCenter.y
              //   const deckInstance = webGLVectorTileLayer.layer.getMap().getDeckGL().getDeckInstance()
              //   const pickInfos = deckInstance.pickMultipleObjects({ x, y, radius: 10, depth: 10 })
              //   const features = pickInfos.map((item) => {
              //     const feature = MapManager.createFeatureFromText('1 1', GeometryFormat.Text, 3857)
              //     feature.setProperties(item.object.properties)
              //     return feature
              //   })
              //   const fc = new FeatureCollection(features)
              //   await this.onFeaturesClick(fc)
              // })
            // }
          }
          if (showDynamicFilterSuccessMessage) {
            this.showSuccess(`Применено динамических фильтров: ${totalDynamicFilters}`)
          }
          break
        case 'OGCFeatures':
          // url = `${this.$config.api}/mapeditor/vectortiles/import/${layerData.source.entityId}/${fieldId}/{z}/{x}/{y}`
          url = layerData.source.OGCFeatures.layer.href
          if (layerData.source.OGCFeatures.proxy.enable && layerData.source.OGCFeatures.proxy.url) {
            url = url.replace(layerData.source.OGCFeatures.serviceUrl, layerData.source.OGCFeatures.proxy.url)
          }
          let headers = {}
          if (layerData.source.OGCFeatures.authorizationMethod === 'basic') {
            headers.Authorization = 'Basic ' + btoa(layerData.source.OGCFeatures.credentials.login + ':' + layerData.source.OGCFeatures.credentials.password)
          }
          params.srs_handling = {
            native_coordinate_system_id: layerData.source.nativeCoordinateSystemId,
            declared_coordinate_system_id: this.declaredCoordinateSystemId,
            srs_handling_type: 'keep_native' // layerData.source.srsHandlingType,
          }
          params.request = {
            method: HTTPMethod.GET,
            base_url: url,
            data: data1,
            headers: headers,
            axios_params: {
              hideNotification: true
            },
            axios: this.$http.create(),
            server_standard: 'OGCFeatures'
          }
          layer = MapManager.createLayer(SourceType.Vector, params)
          break
        case 'Field':
          fieldId = layerData.source.geometryField.substring(5, layerData.source.geometryField.length - 1)
          if (layerData.style.enableCustomStyles) {
            params.style.style_in_feature_attribute = true
            params.style.style_attribute = 'system_style'
            params.style.point_icon_function = (url) => {
              if (!url) {
                return 'empty.png'
              }
              const basePath = `${this.$config.api}/files/mapeditor/images/`
              const arr = url.split('/')
              const guid = arr[arr.length - 1]
              return `${basePath}${guid}.png`
              /* let data = this.pointIconCache.get(guid)
                if (!data) {
                  data = await new PointStyleImageQueryRepository().getByGuid(guid)
                  this.pointIconCache.set(guid, data)
                }
                return `${basePath}${guid}.${data.extension}` */
            }
          }
          // формируем FeatureCollection
          let geometry = this.getModel()[layerData.source.geometryField] || null
          let preparedGeometry = null
          if (geometry !== null) {
            geometry = JSON.parse(geometry)
            // если у компонента карты не включено отображение стилей, то рисуем геометрию без изменений
            // если у компонента карты включено отображение стилей, формируем из каждой
            // когда хранится во внешней таблице - возвращается FeatureCollection
            if (geometry.type === 'FeatureCollection') {
              if (geometry.features) {
                preparedGeometry = geometry
              } else {
                preparedGeometry = null
              }
            } else {
              let properties = {}
              if (layerData.style.enableCustomStyles) {
                let p
                try {
                  p = JSON.parse(this.getRawData()[`style_${fieldId}_`])
                } catch (e) {
                  p = {}
                }
                properties[`system_style`] = p
                preparedGeometry = {
                  type: 'FeatureCollection',
                  features: []
                }

                if (['Point', 'LineString', 'MultiLineString', 'Polygon', 'MultiPolygon'].includes(geometry.type)) {
                  preparedGeometry.features.push({
                    type: 'Feature',
                    geometry: geometry,
                    properties: properties
                  })
                } else if (geometry.type === 'GeometryCollection') {
                  geometry.geometries.forEach(g => {
                    preparedGeometry.features.push({
                      type: 'Feature',
                      geometry: g,
                      properties: properties
                    })
                  })
                }
              } else {
                preparedGeometry = geometry
              }
            }
          }
          params.srs_handling = {
            native_coordinate_system_id: layerData.source.nativeCoordinateSystemId,
            declared_coordinate_system_id: this.declaredCoordinateSystemId,
            srs_handling_type: layerData.source.srsHandlingType
          }
          let sourceType2 = SourceType.Vector
          if (params.style.point &&
              params.style.point.hasOwnProperty('cluster_distance') &&
              params.style.point.cluster_distance > 0) {
            sourceType2 = SourceType.Cluster
          }
          if (preparedGeometry === undefined || preparedGeometry === null) {
            layer = MapManager.createLayer(SourceType.Vector, params)
          } else {
            layer = MapManager.createLayerFromGeoJSON(JSON.stringify(preparedGeometry), sourceType2, params)
            this.getModel()[layerData.source.geometryField] = JSON.stringify(preparedGeometry)
          }
          break
        case 'XYZ':
          layer = MapManager.createLayer(SourceType.XYZ, {
            request: {
              method: HTTPMethod.GET,
              base_url: layerData.source.externalURL
            },
            load_callback: async (layer) => {
              this.changeLayerOpacity(data, data.properties.opacity)
              // loading icon
              data.isLoading = false
            }
          })
          break
        case 'TileArcGISRest':
          layer = MapManager.createLayer(SourceType.TileArcGISRest, {
            request: {
              method: HTTPMethod.GET,
              base_url: layerData.source.externalURL
            },
            load_callback: async () => {
              if (layerData.fit) {
                await this.fitWithDefaultZoom(layer)
              }
            }
          })
          break
        case 'TileWMS':
        case 'WMS':
          layer = MapManager.createLayer(SourceType.TileWMS, {
            request: {
              method: HTTPMethod.GET,
              base_url: layerData.source.externalURL,
              headers: { 'Authorization': localStorage.getItem('user-token') }
            },
            load_callback: async () => {
              if (layerData.fit) {
                await this.fitWithDefaultZoom(layer)
              }
              data.isLoading = false
            }
          })
          break
        case 'WFS':
          params.srs_handling = {
            'native_coordinate_system_id': layerData.source.nativeCoordinateSystemId,
            'declared_coordinate_system_id': this.declaredCoordinateSystemId,
            'srs_handling_type': 'keep_native'
          }
          params.request = {
            method: HTTPMethod.GET,
            base_url: layerData.source.externalURL,
            headers: null,
            axios_params: {
              hideNotification: true
            }
          }
          if (layerData.source.geometryField !== null && layerData.source.geometryField !== '') {
            params.request.geometry_name = layerData.source.geometryField
          }
          // добавляю cql фильтры для деления слоя
          if (layerData.separation.type === 'rules' && (layerData.separation.rule.length !== 0 && layerData.separation.rule.query !== null)) {
            params.request.cql_filter = layerData.separation.rule.script
          }
          if (dynamicFilters && Array.isArray(dynamicFilters)) {
            const df = this.buildDynamicFilters(dynamicFilters, 'WFS')
            if (df !== null && df !== false) {
              activeDynamicFilters = df
              if (params.request.cql_filter) {
                params.request.cql_filter += ' AND ' + df
              } else {
                params.request.cql_filter = df
              }
            }
          }
          layer = MapManager.createLayer(SourceType.Vector, params)
          break
        default:
          console.error(`unknown source type: ${layerData.source}`)
          return
      }
      if (layerData.source.enableExternalSourceFromField) {
        realLayers.forEach((l) => {
          MapManager.addLayer(this.map, l)
          MapManager.setZIndex(l, layerData.zIndex)
        }, this)
      } else {
        MapManager.addLayer(this.map, layer)
        MapManager.setZIndex(layer, layerData.zIndex)
      }
      this.activeLayers.push({
        guid: guid,
        name: data.name,
        layer: layer,
        layerData: layerData,
        filters: layerData.source.filters,
        dynamicFilters: layerData.source.dynamicFilters,
        lastDynamicFilters: activeDynamicFilters,
        lastFilters: filters,
        data: data,
        displayedLayers: realLayers,
        fullAdditionalFields: fullAdditionalFields,
        zIndexWatcher: this.$watch(() => layerData.zIndex, (zIndex) => {
          MapManager.setZIndex(layer, zIndex)
        })
      })
      if (layerData.source.type === 'Field' && layerData.isEditable) {
        this.fieldSourceLayers[guid] = {
          validation: layerData.validation,
          field: layerData.source.geometryField,
          nativeCoordinateSystemId: layerData.source.nativeCoordinateSystemId,
          declaredCoordinateSystemId: this.declaredCoordinateSystemId
        }
      }
      if (layerData.source.type === 'Registry' &&
          this.currentInteraction === INTERACTION_MODES.SELECT.POINT &&
          layerData.source.isVectorTile
      ) {
        // // заново навешиваем обработчик выбора векторнотайловых фич
        // MapManager.setFeatureClickCallback(this.map, null)
        // MapManager.setFeatureClickCallback(this.map, this.onVectorTileFeaturesClick)
      }
      this.reloadInteraction()
      if (!layerData.fit && isNeedFitLayer) {
        await this.fitWithDefaultZoom(layer)
      }
    },
    buildDynamicFilters (dynamicFilters, layerSource) {
      if (!dynamicFilters || !Array.isArray(dynamicFilters)) {
        return null
      }
      let result = null
      switch (layerSource) {
        case 'WFS':
          dynamicFilters.forEach((filter) => {
            if (filter.enable) {
              const filterString = filter.field + ' LIKE %' + filter.value + '%'
              if (result !== null) {
                result += ' AND ' + filterString
              } else {
                result = filterString
              }
            }
          })
          break
        case 'Registry':
        case 'Query':
          dynamicFilters.forEach((filter) => {
            if (filter.enable) {
              if (filter.condition === null) {
                this.showError('Минимум у одного динамического фильтра не выбрано условие')
                return false
              }
              let field = ['xref_field', 'xref_multi_field'].includes(filter.type) ? `attr_${filter.field}_id` : `attr_${filter.field}_`
              if (layerSource === 'Query') {
                field = filter.field
              }
              let objectFilter = {}
              objectFilter[filter.condition] = {}

              switch (filter.condition) {
                case 'like':
                case 'not_like':
                  objectFilter[filter.condition][field] = '%' + filter.value + '%'
                  break
                case 'is_null':
                case 'is_not_null':
                  objectFilter[filter.condition] = field
                  break
                default:
                  objectFilter[filter.condition][field] = '' + filter.value
              }
              if (result) {
                result.and.push(objectFilter)
              } else {
                result = { and: [objectFilter] }
              }
            }
          })
          break
        default:
      }

      return result
    },
    getAdditionalFields (layerData) {
      let result = []
      if (layerData.source.marksField) {
        result.push(layerData.source.type === 'Registry' ? `attr_${layerData.source.marksField}_` : layerData.source.marksField)
      }
      if (layerData.interactive) {
        if (layerData.interactive.card && layerData.interactive.card.card && layerData.interactive.card.card.fieldId) {
          result.push(layerData.interactive.card.card.fieldId)
        }
      }
      if (
        layerData.interactive &&
          layerData.source.type === 'Registry' &&
          layerData.interactive.standardCard
      ) {
        layerData.interactive.standardCard.fields.forEach((item) => {
          result.push(layerData.source.type === 'Registry' ? `attr_${item.id}_` : item.id)
        })
        if (layerData.interactive.standardCard.name) {
          result.push(layerData.interactive.standardCard.name)
        }
      }
      // popup fields
      if (layerData.interactive.popup && layerData.interactive.popup.text) {
        const matches = layerData.interactive.popup.text.match(/(?<=\{{2}).+?(?=\}{2})/g)
        if (matches) {
          matches.forEach((match) => {
            result.push(match.trim())
          }, this)
        }
      }
      if (layerData.source.type === 'Registry') {
        result.push('guid')
        result.push('id')
      }
      // delete doubles
      result = result.filter((item,
        index) => result.indexOf(item) === index)
      return result
    },
    changeLayerOpacity (layer, opacity) {
      let mapLayer = this.activeLayers.find((item) => { return item.guid === layer.guid })
      if (mapLayer) {
        if (mapLayer.layer === null && Array.isArray(mapLayer.displayedLayers)) {
          mapLayer.displayedLayers.forEach(displayedLayer => {
            MapManager.setOpacity(displayedLayer, opacity)
          }, this)
        } else {
          MapManager.setOpacity(mapLayer.layer, opacity)
        }
      }
    },
    changeSelectInteractionType (type) {
      if (type === this.currentInteraction) {
        type = null
      }
      this.currentInteraction = type
    },
    removeInteraction (oldValue) {
      this.drawnPolygonInSelectionMode = null
      switch (oldValue) {
        // drawing
        case INTERACTION_MODES.DRAWING.LINESTRING:
        case INTERACTION_MODES.DRAWING.POINT:
        case INTERACTION_MODES.DRAWING.POLYGON:
        case INTERACTION_MODES.DRAWING.CIRCLE:
          this.setToolsCheckMarks('drawing-geometry', null)
          MapManager.clearInteractions(this.map, [InteractionType.Draw])
          break
          // edit_by_vertices
        case INTERACTION_MODES.EDIT_BY_VERTICES:
          this.setToolsCheckMarks('drawing-geometry', null)
          MapManager.clearInteractions(this.map, [InteractionType.Modify])
          break
          // measure
        case INTERACTION_MODES.MEASURE.LINE:
        case INTERACTION_MODES.MEASURE.SQUARE:
          this.setToolsCheckMarks('measurement', null)
          MapManager.clearInteractions(this.map, [InteractionType.Measure])
          break
        case INTERACTION_MODES.SELECT.POINT:
          MapManager.setFeatureClickCallback(this.map, null)
        case INTERACTION_MODES.SELECT.RECTANGLE:
        case INTERACTION_MODES.SELECT.POLYGON:
        case INTERACTION_MODES.SELECT.CIRCLE:
        case INTERACTION_MODES.SELECT.INTERSECTION:
          this.setToolsCheckMarks('change-selection-type', null)
          MapManager.clearInteractions(this.map, [InteractionType.Select])
          break
        case INTERACTION_MODES.SELECT.ROSREESTR:
          this.$set(this, 'showCadastrPanel', false)
          this.setToolsCheckMarks('change-selection-type', null)
          MapManager.clearCenterMarkers(this.map)
          MapManager.clearInteractions(this.map, [InteractionType.MapCoordinatesClick])
          break
        case INTERACTION_MODES.DEFINE_ADDRESS:
          this.setToolsCheckMarks('position-on', null)
          MapManager.clearInteractions(this.map, [InteractionType.MapCoordinatesClick])
          MapManager.setCursor(this.map, CursorType.Default)
          MapManager.clearCenterMarkers(this.map)
          break
        case INTERACTION_MODES.CREATE_TEXT_BOX:
          MapManager.clearInteractions(this.map, [InteractionType.MapCoordinatesClick])
          this.setToolsCheckMarks('drawing-geometry', null)
          break
        case null:
          break
        default:
          console.log('unknown interaction', oldValue)
      }
    },
    applyInteraction (newValue, showNotification = true) {
      let me = this
      let interactionMode = INTERACTION_MODES_OPTIONS.find((mode) => mode.id === newValue)
      if (this.isInteractiveModeSelectedByUser && interactionMode && showNotification) {
        this.showSuccess(`Выбран интерактив: "${interactionMode.name}"`)
      }
      this.isInteractiveModeSelectedByUser = true
      switch (newValue) {
        case INTERACTION_MODES.DRAWING.LINESTRING:
        case INTERACTION_MODES.DRAWING.POINT:
        case INTERACTION_MODES.DRAWING.POLYGON:
        case INTERACTION_MODES.DRAWING.CIRCLE:
          this.activeInteractiveType.splice(0, 1, 'drawing-geometry')
          this.setToolsCheckMarks('drawing-geometry', newValue)
          const activeLayer = this.getActiveLayer(true)
          if (activeLayer === false) {
            return
          }

          MapManager.setDrawInteraction(
            this.map, // map to draw on
            activeLayer.layer, // layer to draw on
            {
              geometry_type: newValue, // feature type to be drawn
              draw_callback: function (feature) {
                if (activeLayer.layerData.source.type === 'Field') {
                  // console.log(me.getActiveLayerFeatures())
                  me.getModel()[activeLayer.layerData.source.geometryField] = me.getActiveLayerFeatures()
                }
                if (typeof me.interactiveCallback === 'function') {
                  me.interactiveCallback(feature)
                  me.interactiveCallback = null
                }
                me.currentInteraction = null
              }
            }
          )
          break
          // edit by vertices
        case INTERACTION_MODES.EDIT_BY_VERTICES:
          this.activeInteractiveType.splice(0, 1, 'drawing-geometry')
          this.setToolsCheckMarks('drawing-geometry', newValue)
          const activeLayer2 = this.getActiveLayer(true)
          if (activeLayer2 === false) {
            return
          }
          MapManager.setModifyInteraction(this.map,
            {
              'source': activeLayer2.layer,
              'modify_callback': function (features) {
                me.getModel()[activeLayer2.layerData.source.geometryField] = me.getActiveLayerFeatures()
              }
            })
          break
          // measure
        case INTERACTION_MODES.MEASURE.LINE:
        case INTERACTION_MODES.MEASURE.SQUARE:
          this.activeInteractiveType.splice(0, 1, 'measurement')
          this.setToolsCheckMarks('measurement', newValue)
          MapManager.setMeasureInteraction(
            this.map,
            {
              measure_type: newValue,
              measure_popup_settings: {
                'distance_units': this.$locale.map_editor.units.m,
                'area_units': this.$locale.map_editor.units.sqm,
                'rotation_caption': this.$locale.map_editor.units.rotation,
                'angle_caption': this.$locale.map_editor.units.angle
              },
              measure_callback: () => {
                this.currentInteraction = null
              }
            }
          )
          break
          // select
        case INTERACTION_MODES.SELECT.POINT:
          // MapManager.setFeatureClickCallback(this.map, this.onVectorTileFeaturesClick)
          /*
          навесить
          MapManager.setFeatureClickCallback(map, feature_click_callback);
          снять
          MapManager.setFeatureClickCallback(map, null);
          */
        case INTERACTION_MODES.SELECT.RECTANGLE:
        case INTERACTION_MODES.SELECT.POLYGON:
        case INTERACTION_MODES.SELECT.CIRCLE:
          this.activeInteractiveType.splice(0, 1, 'change-selection-type')
          this.setToolsCheckMarks('change-selection-type', newValue)
          let layersForSelectInteraction =
              this.isInteractiveWithActiveLayerOnly && this.activeLayerGuid && this.getActiveLayerByGuid(this.activeLayerGuid)
                ? [this.getActiveLayerByGuid(this.activeLayerGuid).layer] : []
          this.currentInteractiveOnMap = MapManager.setSelectInteraction(this.map, {
            'selection_type': this.currentInteraction,
            'multiple': false,
            'pin': this.isSelectInteractionPin,
            'layers': layersForSelectInteraction,
            'select_callback': this.onFeaturesClick
          })
          if (newValue === INTERACTION_MODES.SELECT.POLYGON) {
            // Если выбор через полигон - вешает слушатель, который будет записывать нарисованную фичу в переменную
            // Иначе ее можно получить только через приватные свойства класса Draw
            // Нужен для получения всех фич внутри нарисованного полигона на слоях WEBGl
            this.currentInteractiveOnMap.getInteraction().on('drawend', (event) => {
              this.drawnPolygonInSelectionMode = event.feature
            })
            this.currentInteractiveOnMap.getInteraction().on('drawstart', () => {
              this.drawnPolygonInSelectionMode = null
            })
          }
          break
        case INTERACTION_MODES.SELECT.INTERSECTION:
          this.activeInteractiveType.splice(0, 1, 'change-selection-type')
          this.setToolsCheckMarks('change-selection-type', newValue)
          MapManager.setSelectInteraction(this.map, {
            'selection_type': 'polygon'/* this.currentInteraction */,
            'select_callback': this.intersectionFeatureCollection
          })
          break
        case INTERACTION_MODES.SELECT.ROSREESTR:
          this.activeInteractiveType.splice(0, 1, 'change-selection-type')
          this.setToolsCheckMarks('change-selection-type', newValue)
          this.$set(this, 'showToolsPanel', false)
          MapManager.setMapCoordinatesInteraction(this.map, {
            'type': EventType.Click,
            'declared_coordinate_system_id': 4326,
            'map_coordinates_callback': this.onMapClick
          })
          break
        case INTERACTION_MODES.DEFINE_ADDRESS:
          this.activeInteractiveType.splice(0, 1, 'position-on')
          this.setToolsCheckMarks('position-on', newValue)
          MapManager.setMapCoordinatesInteraction(this.map, {
            'type': EventType.Click,
            'declared_coordinate_system_id': 4326,
            'map_coordinates_callback': this.onDefineAddressClick
          })
          MapManager.setCursor(this.map, CursorType.Pointer)
          break
        case INTERACTION_MODES.CREATE_TEXT_BOX:
          this.activeInteractiveType.splice(0, 1, 'drawing-geometry')
          this.setToolsCheckMarks('drawing-geometry', 'CreateTextBox')
          MapManager.setMapCoordinatesInteraction(this.map, {
            'type': EventType.Click,
            'declared_coordinate_system_id': 4326,
            'map_coordinates_callback': this.addTextToActiveLayer
          })
          break
        case null:
          this.activeInteractiveType.splice(0, this.activeInteractiveType.length)
          break
        default:
          console.log('unknown interaction', newValue)
      }
    },
    reloadInteraction () {
      if (!this.isEditor()) {
        if (this.currentInteraction) {
          this.removeInteraction(this.currentInteraction)
        }
        this.applyInteraction(this.currentInteraction, false)
      }
    },
    addTextToActiveLayer (coordinates, pixelCoordinates, mapProjection) {
      const me = this
      this.$prompt('Введите текст надписи', 'Введите текст надписи', {})
        .then((value) => {
          let opts = {
            label: {
              font: 'italic bold 10px Verdana',
              offset: [0, 0],
              overflow: 'false',
              placement: 'point',
              rotate_with_view: 'false',
              rotation: 0,
              color: '#000',
              stroke_width: 1,
              text: value.value
            },
            polygon: {
              color: '#505050',
              stroke_width: 1,
              background_color: '#FFF',
              opacity: 100
            }
          }
          if (this.inscriptionStyle) {
            this.inscriptionStyle.label?.forEach((item) => {
              let styleName = item.id
              opts.label[styleName] = item.value
            })
            this.inscriptionStyle.polygon?.forEach((item) => {
              let styleName = item.id
              opts.polygon[styleName] = item.value
            })
          }
          MapManager.addText(
            me.map,
            pixelCoordinates,
            opts
          )
          me.currentInteraction = null
        })
        .catch((error) => {
          console.log('alo', error)
          me.currentInteraction = null
        })
    },
    setToolsCheckMarks (key, value) {
      if (this.toolsCheckMarks[key] === undefined) {
        this.$set(this.toolsCheckMarks, key, [])
      }
      if (value === null) {
        this.toolsCheckMarks[key].splice(0, this.toolsCheckMarks[key].length)
      } else {
        if (this.toolsCheckMarks[key].length > 0) {
          // если там уже что-то есть
          let index = this.toolsCheckMarks[key].indexOf(value)
          if (index !== -1) {
            // добавляемое значение уже есть - удаляем
            this.toolsCheckMarks[key].splice(index, 1)
          } else {
            // иначе добавляем
            this.toolsCheckMarks[key].splice(this.toolsCheckMarks[key].length, 0, value)
          }
        } else {
          // если пустой массив
          this.toolsCheckMarks[key].splice(0, this.toolsCheckMarks[key].length, value)
        }
      }
    },
    measurement (type) {
      switch (type) {
        case 'distance':
        case 'area':
          if (this.currentInteraction === type) {
            this.currentInteraction = null
          } else {
            this.currentInteraction = type
          }
          break
        case 'reset':
          MapManager.clearMeasureResult(this.map)
          this.currentInteraction = null
          break
        default:
          console.log('unknown measurement type')
      }
    },
    closeCadastrPanel () {
      this.showCadastrPanel = false
      MapManager.clearCenterMarkers(this.map)
    },
    showPositionOnWindow (type) {
      let toolProperties = this.tools['position-on'].properties
      switch (type) {
        case 'defineAddress':
          if (type === this.currentInteraction) {
            type = null
          }
          this.currentInteraction = type
          break
        case 'address':
          let inputValue = ''
          if (toolProperties !== undefined && toolProperties.field !== undefined) {
            if (toolProperties.field) {
              let fieldValue = this.getModel()[toolProperties.field]
              if (fieldValue) {
                switch (typeof fieldValue) {
                  case 'string':
                    inputValue = fieldValue
                    break
                  case 'object':
                    if (Array.isArray(fieldValue)) {
                      if (fieldValue.length > 0) {
                        fieldValue = fieldValue[0]
                      }
                    }
                    inputValue = fieldValue.name || ''
                    break
                  default:
                    console.log('Unknown associated field!')
                }
              }
            }
          }
          this.positionOnAddressSettings = {
            addressPrefix: toolProperties.defaultAddress,
            valueByDefault: inputValue
          }
        case 'coordinates':
        case 'cadastral_number':
          this.$refs['position-on-window'].show(type)
          break
        default:
          console.log('position-on tool error')
          break
      }
    },
    async positionOn (coordinates, cs) {
      await this.loadAndRegisterCS(cs)
      MapManager.clearCenterMarkers(this.map)
      MapManager.setCenter(
        this.map,
        {
          x: coordinates.x,
          y: coordinates.y,
          show_marker: this.position.show_marker,
          declared_coordinate_system_id: cs
        }
      )
    },
    getActiveLayer (isEditable) {
      if (!this.activeLayerGuid) {
        this.showError('Не выбран активный слой')
        return false
      }
      const activeLayerData = this.activeLayers.find((item) => item.guid === this.activeLayerGuid)
      if (!activeLayerData) {
        this.showError('Активный слой не включен')
        return false
      }
      if (isEditable && !activeLayerData.layerData.isEditable) {
        this.showError('Активный слой является нередактируемым')
        return false
      }
      return activeLayerData
    },
    getActiveLayerFeatures () {
      const activeLayerData = this.getActiveLayer(true)
      if (activeLayerData === false) {
        return false
      }
      const fc = MapManager.getFeatures(activeLayerData.layer)
      if (activeLayerData.layerData.validation === 'warning') {
        if (!fc.getFeatures().every(f => { return MapManager.isValid(f) })) {
          this.showError('Один из контуров не является валидным')
        }
      }
      return MapManager.getFeaturesAsFeatureCollection(fc, this.declaredCoordinateSystemId, activeLayerData.layer.srsId)
    },
    search () {
      this.$set(this, 'isLoading', !this.isLoading)
    },
    refreshGeometryField (showMessage, newFeature) {
      const activeLayer = this.getActiveLayer(true)
      if (activeLayer === false) {
        return
      }
      this.getModel()[activeLayer.layerData.source.geometryField] = this.getActiveLayerFeatures()
      if (newFeature) {
        this.zoomOnFeature(newFeature, false)
      } else {
        MapManager.fitLayer(this.map, activeLayer.layer)
      }
      if (showMessage) {
        if (showMessage === true) {
          this.showSuccess('Успешно добавлено')
        } else {
          this.showSuccess(showMessage)
        }
      }
    },
    drawingGeometry (type) {
      let activeLayer = false
      if (!['CreateTextBox', 'DeleteTextBoxes', 'Edit'].includes(type)) {
        activeLayer = this.getActiveLayer(true)
        if (activeLayer === false) {
          return
        }
      }
      let toolProperties = this.tools['drawing-geometry'].properties
      const me = this
      switch (type) {
        case 'Point':
        case 'LineString':
        case 'Polygon':
        case 'Circle':
          if (Object.values(INTERACTION_MODES.DRAWING).includes(this.currentInteraction)) {
            me.currentInteraction = null
            this.snapMode = false
          } else {
            me.currentInteraction = type
          }
          break
        case 'Edit':
          if (this.activeFeatures.length !== 1) {
            this.showError('Должна быть выбрана одна геометрия')
            return
          }
          if (!this.activeFeatures[0].layerProperties.isEditable) {
            this.showError('Слой выбранной геометрии не редактируемый')
            return
          }
          const layer = this.activeLayers.find(x => x.guid === this.activeFeatures[0].layerGuid)
          if (!layer) {
            console.log('layer not exist')
            return
          }
          this.$refs.tools_panel.enterEditGeometry(
            this.activeFeatures[0].item,
            layer
          )
          break
        case 'Snap':
          if (this.snapMode) {
            MapManager.clearInteractions(this.map, [InteractionType.Snap])
            this.setToolsCheckMarks('drawing-geometry', 'Snap')
            this.snapMode = false
          } else {
            if (Object.values(INTERACTION_MODES.DRAWING).includes(this.currentInteraction)) {
              if (toolProperties && toolProperties.snap && toolProperties.snap.enable) {
                MapManager.setSnapInteraction(this.map, {
                  layers: [activeLayer.layer],
                  pixelTolerance: toolProperties.radius
                })
              }
              this.setToolsCheckMarks('drawing-geometry', 'Snap')
              this.snapMode = true
            }
          }
          break
        case 'Vertices':
          this.createGeometryByVertices.layer = activeLayer.layer
          this.$refs['create-geometry-by-vertices'].show()
          break
        case 'EditByVertices':
          // пока так, как появится в отдельной кнопке - перенесем
          me.isEditVerticesActive = !me.isEditVerticesActive
          if (me.isEditVerticesActive) {
            me.currentInteraction = type
          } else {
            me.currentInteraction = null
          }
          break
        case 'CreateTextBox':
          this.currentInteraction = this.currentInteraction !== type ? type : null
          break
        case 'DeleteTextBoxes':
          MapManager.clearTemporaryTexts(this.map)
          this.currentInteraction = null
          break
        default:
          console.log('Неизвестный тип создания геометрии')
      }
    },
    deleteFeatures () {
      if (this.activeFeatures.length === 0) {
        this.$message.error('Не выбрана геометрия для удаления')
        return false
      }
      this.$confirm(`Вы уверены что хотите удалить выбранные геометрии (${this.activeFeatures.length} шт.)`, 'Внимание', {
        confirmButtonText: 'Да',
        cancelButtonText: 'Нет'
      }).then(() => {
        let count = 0
        let featuresGuidToDelete = []
        for (let i = 0; i < this.activeFeatures.length; i++) {
          const a = this.deleteGeometry(this.activeFeatures[i], true)
          if (a) {
            featuresGuidToDelete.push(this.activeFeatures[i].guid)
            count++
          }
        }
        featuresGuidToDelete.forEach(guid => {
          this.activeFeatures.splice(this.activeFeatures.findIndex(x => x.guid === guid), 1)
        }, this)
        if (count === 0) {
          this.$message.error('Выбранные геометрии находятся на нередактируемом слое')
        } else {
          if (count < this.activeFeatures.length) {
            this.$message.success(`Удалено ${count} геометрий, ${1 + this.activeFeatures.length - count} находятся на нередактируемых слоях`)
          } else {
            this.$message.success(`Удалено ${count} геометрий`)
          }
          this.activeFeatures.splice(0, this.activeFeatures.length)
        }
      })
    },
    deleteGeometry (item, hideMessage) {
      // item = (element|element guid) of this.activeFeatures
      if (typeof item === 'string') {
        item = this.activeFeatures.find(x => x.properties.guid === item)
      }
      if (!item.layerProperties.isEditable) {
        if (!hideMessage) {
          this.$message.error('Не редактируемый слой')
        }
        return false
      }
      if (item.layerProperties.source.type === 'Field') {
        // очистить поле с геометрией
        MapManager.removeFeatures(this.map, new FeatureCollection([item.item]))
        let newValue = this.getActiveLayerFeatures()
        if (newValue === '{"type":"FeatureCollection","features":[]}') {
          newValue = null
        }
        this.getModel()[item.layerProperties.source.geometryField] = newValue

        return true
      }
      if (!['Registry', 'Query'].includes(item.layerProperties.source.type)) {
        return false
      }

      let formData = new FormData()
      let id = item.properties['id']
      formData.append('id', id)
      formData.append('guid', '"' + item.properties.guid + '"')
      // geometry field
      let geometryField = `attr_${item.layerProperties.source.geometryField}_`
      if (item.layerProperties.source.type === 'Query') {
        geometryField = item.layerProperties.source.geometryField
      }
      formData.append(geometryField, null)
      // entityId
      let entityId = item.layerProperties.source.entityId
      if (item.layerProperties.source.type === 'Query') {
        entityId = item.layerProperties.interactive.card.card.registryId
      }

      this.isLoading = true
      this.$http.put(
        `${this.$config.api}/registryservice/registry/${entityId}/records/${id}`,
        formData,
        {
          hideNotification: true,
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      ).then(() => {
        this.removeFeature(item.item)
        this.isLoading = false
        this.activeFeatures.splice(this.activeFeatures.findIndex(x => x.properties.guid === item.properties.guid), 1)
      })
        .catch((e) => {
          console.log(e)
          console.log('удалить из базы не получилось')
          this.isLoading = false
        })
      return true
    },
    removeFeature (feature) {
      MapManager.removeFeatures(this.map, new FeatureCollection([feature]))
    },
    showSuccess (text) {
      this.$message({
        type: 'success',
        dangerouslyUseHTMLString: true,
        message: text
      })
    },
    showError (text) {
      this.$message({
        type: 'error',
        dangerouslyUseHTMLString: true,
        message: text
      })
    },
    exportFromToolsPanel (action, selectedObjects, layersSettings) {
      if (action === 'excel') {
        this.exportFeatures('features-excel', selectedObjects, layersSettings)
      }
    },
    async onDefineAddressClick (coordinates) {
      const toolProperties = this.tools['position-on'].properties
      if (!toolProperties.isDefineAddressEnable || toolProperties.defineAddressMapping.length === 0) {
        this.showError('Настройки "Определить адрес" не найдены')
        return
      }
      // MapManager.
      MapManager.clearCenterMarkers(this.map)
      MapManager.setCenter(
        this.map,
        {
          x: coordinates[0],
          y: coordinates[1],
          show_marker: this.position.show_marker,
          declared_coordinate_system_id: 4326
        }
      )
      this.$daData
        .post(
          `https://suggestions.dadata.ru/suggestions/api/4_1/rs/geolocate/address`,
          { lat: coordinates[1], lon: coordinates[0] },
          { hideNotification: true }
        )
        .then((response) => {
          if (!response.data.suggestions || response.data.suggestions.length < 1) {
            this.showError('В указанной точке ничего не найдено')
            return
          }
          toolProperties.defineAddressMapping.forEach((mappingItem) => {
            let valueName = mappingItem.value_id.split('.')
            let value = response.data.suggestions[0]
            valueName.forEach((vn) => {
              value = value[vn]
            })
            this.getModel()[mappingItem.field_id] = value
          })
        })
    },
    async onMapClick (coordinates) {
      // this.onRosreestrClick([43.581577, 58.154024])
      let centerCoordinates = coordinates
      let newCenterCoordinatesArea = null
      let newCenterCoordinatesBuilding = null
      let newCenterCoordinatesZouit = null
      let isTargetNotEmpty = false
      // участок
      this.$set(this, 'showCadastrPanel', false)
      let areaTarget = await APIClient.shared.request(new RosreestrAPI.GetObjectByCoordinates(coordinates, 1))
      if (areaTarget.total > 0) {
        newCenterCoordinatesArea = [areaTarget.features[0].center.x, areaTarget.features[0].center.y]
        isTargetNotEmpty = true
        let areaInfo = await APIClient.shared.request(new RosreestrAPI.GetObjectInfoByCadastrNumber(areaTarget.features[0].attrs.id, 1))
        const area = areaInfo.feature.attrs
        this.$set(this.cadastrInfo, 'area', {
          cn: area.cn,
          category_type: area.category_type,
          address: area.address,
          area_value: area.area_value,
          cad_cost: area.cad_cost
        })
        this.$set(this, 'showCadastrPanel', true)
      } else {
        this.$set(this.cadastrInfo, 'area', {})
      }
      // здание
      let buildingTarget = await APIClient.shared.request(new RosreestrAPI.GetObjectByCoordinates(coordinates, 5))
      if (buildingTarget.total > 0) {
        newCenterCoordinatesBuilding = [buildingTarget.features[0].center.x, buildingTarget.features[0].center.y]
        isTargetNotEmpty = true
        let buildingInfo = await APIClient.shared.request(new RosreestrAPI.GetObjectInfoByCadastrNumber(buildingTarget.features[0].attrs.id, 5))
        const building = buildingInfo.feature.attrs
        this.$set(this.cadastrInfo, 'building', {
          cn: building.cn,
          name: building.name,
          purpose: building.purpose,
          address: building.address,
          year_built: building.year_built,
          area_value: building.area_value,
          cad_cost: building.cad_cost,
          floors: building.floors
        })
        this.$set(this, 'showCadastrPanel', true)
      } else {
        this.$set(this.cadastrInfo, 'building', {})
      }
      // ЗОУИТ
      let zouitTarget = await APIClient.shared.request(new RosreestrAPI.GetObjectByCoordinates(coordinates, 20/* 10 */))
      if (zouitTarget.total > 0) {
        newCenterCoordinatesZouit = [zouitTarget.features[0].center.x, zouitTarget.features[0].center.y]
        isTargetNotEmpty = true
        let zouitInfo = await APIClient.shared.request(new RosreestrAPI.GetObjectInfoByCadastrNumber(zouitTarget.features[0].attrs.id, 20/* 10 */))
        const zouit = zouitInfo.feature.attrs
        this.$set(this.cadastrInfo, 'zouit', {
          number_zone: zouit.number_zone,
          acnum: zouit.acnum,
          rayon_cn: zouit.rayon_cn,
          name_zone: zouit.name_zone,
          zone_kind: zouit.zone_kind,
          content_restrictions: zouit.content_restrictions
        })
        this.$set(this, 'showCadastrPanel', true)
      } else {
        this.$set(this.cadastrInfo, 'zouit', {})
      }
      if (!isTargetNotEmpty) {
        this.showError('Публичная кадастровая карта не содержит сведений по выбранным координатам')
      }
      MapManager.clearCenterMarkers(this.map)
      MapManager.setCenter(
        this.map,
        {
          x: newCenterCoordinatesArea[0] || newCenterCoordinatesBuilding[0] || newCenterCoordinatesZouit[0] || centerCoordinates[0],
          y: newCenterCoordinatesArea[1] || newCenterCoordinatesBuilding[1] || newCenterCoordinatesZouit[1] || centerCoordinates[1],
          show_marker: this.position.show_marker,
          declared_coordinate_system_id: isTargetNotEmpty ? 3857 : 4326
        }
      )
    },
    async loadVectorTileAttributes (features) {
      let idsForRequest = {}
      for (let i = 0; i < features.length; i++) {
        let layer = null
        let item = features[i]
        let properties = item.getFeature().getProperties()

        if (item.layer && item.layer.layer) {
          layer = this.activeLayers.find((l) => item.layer.layer === l.layer.layer)
        } else if (properties.layer_guid) {
          layer = this.activeLayers.find((l) => properties.layer_guid === l.guid)
        }
        if (layer &&
            layer.layerData.source.type === 'Registry' &&
            layer.layerData.source.isVectorTile &&
            layer.fullAdditionalFields
        ) {
          const id = parseInt(properties.record_id) || parseInt(properties.id)
          if (idsForRequest.hasOwnProperty(layer.guid)) {
            if (!idsForRequest[layer.guid].ids.find(x => x === id)) {
              idsForRequest[layer.guid].ids.push(id)
            }
          } else {
            idsForRequest[layer.guid] = {
              ids: [id],
              entityId: layer.layerData.source.entityId,
              fields: layer.fullAdditionalFields
            }
          }
        }
      }

      let additionalProperties = {}
      for (const [key, value] of Object.entries(idsForRequest)) {
        additionalProperties[key] = []
        const a = await APIClient.shared.request(
          new MapEditorAPI.GetVTLayerFeaturesAttributes(
            value.entityId,
            value.fields,
            value.ids
          )
        )

        // console.log(a)
        a.forEach(d => {
          additionalProperties[key].push({ id: d.id, properties: d })
        })
      }

      for (let i = 0; i < features.length; i++) {
        let properties = features[i].getFeature().getProperties()
        const featureId = parseInt(properties.record_id) || parseInt(properties.id)
        if (
          additionalProperties[properties.layer_guid]/* свойства по фичам слоя есть */
        ) {
          const addProps = additionalProperties[properties.layer_guid].find(x => x.id === featureId)
          if (addProps) {
            properties = { ...properties, ...addProps.properties }
            features[i].extendedProperties = properties
          }
        }
      }
      return features
    },
    async onVectorTileFeaturesClick (data) {
      await this.onFeaturesClick(data, true)
    },
    async onFeaturesClick (data, isVectorTileHandler) {
      await this.$nextTick() // без этого не успевает просчитаться нарисованный полигон при выборе геометрии
      if (this.currentInteraction === INTERACTION_MODES.SELECT.RECTANGLE) {
        if (data.getFeatures().length < 1) {
          await MapManager.clearSelection(this.map)
        }
        await this.webGLLayerClearHighlightedFeature()
        await this.getWebGLFeaturesIntersectionInRectangle(data)
        await this.getVectorTileFeaturesIntersectionInPolygon(data)
      } else if (this.currentInteraction === INTERACTION_MODES.SELECT.POLYGON && this.drawnPolygonInSelectionMode) {
        if (data.getFeatures().length < 1) {
          await MapManager.clearSelection(this.map)
        }
        await this.webGLLayerClearHighlightedFeature()
        await this.getWebGLFeaturesIntersectionInPolygon(data)
        await this.getVectorTileFeaturesIntersectionInPolygon(data)
      } else if (this.currentInteraction === INTERACTION_MODES.SELECT.POINT && this.pixelOfLastClick) {
        await this.getVectorTileFeaturesIntersectionInPoint(data)
        await this.getWebGlFeaturesIntersectionInPoint(data)
      }

      let interactive = []
      this.showCardsPanel = false
      const features = await this.loadVectorTileAttributes(data.features)
      // загружаем атрибуты для фич векторных тайлов
      for (const item of features) {
        // console.log(item)
        let layer = null
        let properties = item.extendedProperties || item.getFeature().getProperties()
        if (item.layer && item.layer.layer) {
          layer = this.activeLayers.find((l) => item.layer.layer === l.layer.layer)
        } else if (properties.layer_guid) {
          layer = this.activeLayers.find((l) => properties.layer_guid === l.guid)
        }
        if (layer) {
          let style = item?.layer?.layer.getStyle()(item.getFeature())
          let label = ''
          // костыль похоже
          if (Array.isArray(style)) {
            style = style.pop()
          }
          if (style) {
            if (style.getText() !== null) {
              label = style.getText().getText()
            }
          }

          interactive.push({
            settings: layer.layerData.interactive,
            properties: properties,
            layerName: layer.name,
            layerGuid: layer.guid,
            layerProperties: layer.layerData,
            label: label,
            featureType: item.getType(),
            nativeCoordinateSystemId: layer.layerData.source.nativeCoordinateSystemId,
            coordinates: [],
            item: item,
            feature: item.getFeature()
          })
        }
      }
      // если клик по одному объекту и по умолчанию открытие карточки реестра, то открываем
      // нужно полностью переделать на ActionExecutor
      if (interactive.length === 1) {
        let object = interactive[0]
        if (object.layerProperties.interactive.type === 'open_registry_card' && object.settings.card.card?.containerAlias) {
          let modelData = Object.fromEntries(
            Object.entries(object.properties).filter(([key, value]) => key.includes('attr_'))
          )
          await ActionExecutor.execute(this, { readonly: this._isReadonly, pluginName: this.pluginName, action: object.settings.card, event: event }, modelData)
          return false
        } else if (object.layerProperties.interactive.type === 'open_registry_card') {
          this.openCard(object.settings.card.card, object.properties)
          return false
        }
      }

      if (this.currentInteraction !== INTERACTION_MODES.SELECT.POINT) {
        this.vectorTileActiveFeatures = []
        this.webglActiveFeatures = []
      }


      this.vectorTileActiveFeatures = interactive.filter((interactiveFeature) => interactiveFeature.layerProperties.source.isVectorTile && !interactiveFeature.layerProperties.source.isWebGLVectorTile)
      this.webglActiveFeatures = Array.from(new Set(interactive.filter((interactiveFeature) => interactiveFeature.layerProperties.source.isVectorTile && interactiveFeature.layerProperties.source.isWebGLVectorTile)))
      this.activeFeatures = interactive.filter((interactiveFeature) => !interactiveFeature.layerProperties.source.isVectorTile)
      this.selectedObjects = interactive.filter((interactiveFeature) => !interactiveFeature.layerProperties.source.isVectorTile)
      if (interactive.length === 1 && interactive[0].layerProperties.interactive.type === 'nothing') {

      } else if (interactive.length !== 0) {
        if (!this.showToolsPanel) {
          this.showToolsPanel = true
        }
        this.$refs.tools_panel.openTabByName('objects_settings')
      }
    },
    async openCardFromPanel (object) {
      let me = this
      const listener = async () => {
        const layerGuid = object.properties?.layer_guid
        if (!layerGuid) {
          console.warn('Not found layer_guid for redraw data')
          return
        }
        const layer = me.activeLayers.find((l) => layerGuid === l.guid)
        if (!layer) {
          console.warn('Not found layer for redraw data')
          return
        }

        const activeFeature = me.activeFeatures.find((f) => f.layerGuid === layerGuid && f.properties?.id === object.properties.id)
        if (!activeFeature) {
          console.warn('Not found activeFeature for redraw data')
          return
        }

        let geometryField = layer.layerData?.source?.geometryField
        if (!geometryField) {
          console.warn('Not found geometryField for redraw data')
          return
        }
        geometryField = `attr_${geometryField}_`
        const fields = layer.fullAdditionalFields
        fields.push(geometryField)
        const data = await APIClient.shared.request(
          new MapEditorAPI.GetVTLayerFeaturesAttributes(
            layer.layerData.source.entityId,
            fields,
            [object.properties.id]
          )
        )
        if (!data || data.length === 0) {
          console.warn('Data not loaded for redraw data')
          return
        }
        for (let key in data[0]) {
          me.$set(activeFeature.properties, key, data[0][key])
        }

        const newGeometry = data[0][geometryField]
        const newFeature = MapManager.createFeatureFromText(newGeometry, GeometryFormat.GeoJSON, layer.layerData.source.nativeCoordinateSystemId)

        // MapManager.removeFeatures(me.map, new FeatureCollection([activeFeature]))
        // MapManager.addFeatures(me.map, layer.layer, new FeatureCollection([newFeature]))

        me.activeFeatures = me.activeFeatures.filter((a) => a !== activeFeature)
        me.activeFeatures.push(activeFeature)
      }
      // нужно полностью переделать на ActionExecutor
      if (object.settings.hasOwnProperty('containerAlias') && object.settings.containerAlias) {
        let modelData = Object.fromEntries(Object.entries(object.properties).filter(([key, value]) => key.includes('attr_')))
        let actionData = {
          card: Object.assign({}, object.settings),
          type: 'open_card'
        }
        await ActionExecutor.execute(this,{readonly: this._isReadonly, pluginName: this.pluginName, action: actionData, event: event}, modelData)
      } else {
        this.openCard(Object.assign({ updateListener: listener }, object.settings), object.properties)
      }
    },
    setCenterWithZoom (x, y, zoom, srid) {
      if (!srid) {
        srid = 3857
      }
      MapManager.setCenter(this.map, {
        x: x,
        y: y,
        declared_coordinate_system_id: srid,
        show_marker: false
      })
      this.setZoom(zoom)
      this.setZoomValue(zoom)
    },
    setZoom (newZoomValue) {
      const view = this.map.map.getView()
      const newZoom = view.getConstrainedZoom(newZoomValue)
      if (view.getAnimating()) {
        view.cancelAnimations()
      }
      view.animate({
        zoom: newZoom,
        duration: 250,
        easing: easeOut
      })
    },
    async fitWithDefaultZoom (layer) {
      await MapManager.fitLayer(this.map, layer)
      const view = this.map.map.getView()
      const currentZoom = view.getZoom()
      if (currentZoom > this.defaultZoom) {
        this.setZoom(this.defaultZoom)
      }
    },
    changeZoom (type) {
      let delta = 0
      if (type === 'plus') {
        delta += 1
      } else {
        delta -= 1
      }
      const view = this.map.map.getView()
      const currentZoom = view.getZoom()
      const newZoom = view.getConstrainedZoom(currentZoom + delta)
      if (view.getAnimating()) {
        view.cancelAnimations()
      }
      view.animate({
        zoom: newZoom,
        duration: 250,
        easing: easeOut
      })
    },
    zoomOnFeature (item, showCenterMarker) {
      MapManager.fitFeatures(this.map, new FeatureCollection([item.feature]), this.defaultZoom, showCenterMarker ?? false)
    },
    getDefaultCenter () {
      let object = {
        x: null,
        y: null,
        center_by_coordinates: false
      }
      let value = false
      if (typeof this.defaultCenter === 'string') {
        value = this.defaultCenter
      }
      if (this.defaultCenter.coordinates_from_component === false) {
        value = this.defaultCenter.value
      }
      if (value === false) {
        return object
      }
      const x = value ? value.split(',')[0].trim() : undefined
      const y = value ? value.split(',')[1].trim() : undefined
      if (typeof x !== 'undefined' && typeof y !== 'undefined') {
        object.x = x
        object.y = y
        object.center_by_coordinates = true
      }

      return object
    },
    async googleStreetView () {
      this.isGoogleStreetViewActive = !this.isGoogleStreetViewActive
      let me = this
      if (this.isGoogleStreetViewActive) {
        if (this.google === null) {
          let loader = new google.Loader('AIzaSyCLPWXu_Cc8sEN1g-QsmHTPd5a2kvXlpMQ')
          await loader.load()
            .then(function (google) { me.google = google })
            .catch((answer) => { me.showError('Ошибка загрузки') })
        }
        let svContainer = this.$refs['street-view']
        const view = this.map.map.getView()
        const coord = this.transform(
          view.getCenter(),
          this.declaredCoordinateSystemId,
          this.googleMapsCoordinateSystemId
        )
        if (!this.googlePanorama) {
          this.googlePanorama = new this.google.maps.StreetViewPanorama(svContainer, {
            position: { lat: coord[1], lng: coord[0] },
            pov: { heading: 165, pitch: 0 },
            zoom: 0,
            visible: true
          })
          this.googlePanorama.addListener('position_changed', () => {
            const coord = this.transform(
              [
                this.googlePanorama.getPosition().lng(),
                this.googlePanorama.getPosition().lat()
              ],
              this.googleMapsCoordinateSystemId,
              this.declaredCoordinateSystemId
            )
            view.setCenter(coord)
          })
        } else {
          this.googlePanorama.setPosition(
            new this.google.maps.LatLng(coord[1], coord[0])
          )
        }
        this.map.map.on('click', e => {
          if (this.isGoogleStreetViewActive) {
            const coord = this.transform(
              e.coordinate,
              this.declaredCoordinateSystemId,
              this.googleMapsCoordinateSystemId
            )
            this.googlePanorama.setPosition(
              new this.google.maps.LatLng(coord[1], coord[0])
            )
          }
        })
      }
      me.$nextTick(() => {
        setTimeout(() => MapManager.updateSize(me.map), 0)
      })
    },
    async importGeoJSONFeatures (featureCollection, srsId, fitFeatures, showMessage) {
      const activeLayerData = this.getActiveLayer(true)
      // проставляем данные из мапинга
      if (this.tools['import-features'] &&
          this.tools['import-features'].properties &&
          this.tools['import-features'].properties.mapping &&
          Array.isArray(this.tools['import-features'].properties.mapping)) {
        for (let i = 0; i < this.tools['import-features'].properties.mapping.length; i++) {
          const item = this.tools['import-features'].properties.mapping[i]
          // сейчас работает только для файловых полей
          if (item.property === 'filename') {
            for (let j = 0; j < featureCollection.features.length; j++) {
              const feature = featureCollection.features[j]
              if (!feature.properties.filename || !feature.properties.realfilename) {
                continue
              }
              const filename = feature.properties.realfilename.split('/').findLast(x => true)
              // скачиваем файл
              const file = await this.$http({
                method: 'get',
                url: `${this.$config.api}/files/${filename}`,
                responseType: 'blob'
              })
              // прикрепляем файл к карточке
              let data = new FormData()
              data.append('record_id', this.getModel()['id'])
              data.append('registry_id', this.getCard().getRegistryId())
              data.append(item.field, new Blob([file.data]), feature.properties.filename)
              const response = await this.$http({
                method: 'post',
                url: `${this.$config.api}/registryservice/attachments`,
                data: data,
                headers: { 'Content-Type': `multipart/form-data` }
              })
              // обновляем файловое поле в карточке (в бэке все хорошо)
              if (!this.getModel()[item.field]) {
                this.$set(this.getModel(), item.field, '[]')
              }
              let _file = response.data[0]
              let field = this.getModel()[item.field]
              field = JSON.parse(field)
              field.push({
                name: `${_file.fileName}`,
                url: `${this.$config.api}/files/${_file.guid}.${_file.extension}`,
                uploaded: true
              })
              this.$set(this.getModel(), item.field, JSON.stringify(field))
              this.showSuccess('Файл прикреплен')
            }
          }
        }
      }
      // проставляем геометрию
      if (srsId) {
        await this.loadAndRegisterCS(srsId)
      }
      const fc = MapManager.createFeatureCollectionFromGeoJSON(
        JSON.stringify(featureCollection),
        activeLayerData.layerData.source.nativeCoordinateSystemId,
        this.declaredCoordinateSystemId
      )
      MapManager.addFeatures(this.map, activeLayerData.layer, fc)
      if (fitFeatures !== false) {
        MapManager.fitFeatures(this.map, fc)
      }
      this.getModel()[activeLayerData.layerData.source.geometryField] = this.getActiveLayerFeatures()
      if (showMessage !== false) {
        this.$message({
          message: `Было добавлено ${featureCollection.features.length} геометрий`,
          type: 'success'
        })
      }
      return featureCollection.features.length
    },
    async importWKTString (stringWKT, srsId, fitFeatures) {
      const activeLayerData = this.getActiveLayer(true)
      await this.loadAndRegisterCS(srsId)
      const feature = MapManager.createFeatureFromText(
        stringWKT,
        GeometryFormat.WKT,
        srsId,
        this.declaredCoordinateSystemId
      )
      MapManager.addFeatures(
        this.map,
        activeLayerData.layer,
        new FeatureCollection([feature])
      )

      if (fitFeatures === true) {
        MapManager.fitFeatures(this.map, new FeatureCollection([feature]))
        const view = this.map.map.getView()
        const currentZoom = view.getZoom()
        if (currentZoom > this.defaultZoom) {
          this.setZoom(this.defaultZoom)
        }
      }

      this.getModel()[activeLayerData.layerData.source.geometryField] = this.getActiveLayerFeatures()

      this.$message({
        message: `Геометрия успешно импортирована`,
        type: 'success'
      })
    },
    async exportFeatures (type, selectedObjects, layersSettings) {
      const cellStyle = { bold: true }
      switch (type) {
        case 'features-excel':
          const ExcelJS = require('exceljs')
          const workbook = new ExcelJS.Workbook()
          workbook.addWorksheet('Sheet1')
          const worksheet = workbook.getWorksheet('Sheet1')
          const column = worksheet.getColumn(1)

          let i = 1
          Object.values(selectedObjects).forEach((layer) => {
            // set header
            const headerRow = worksheet.getRow(i++)
            headerRow.getCell(1).value = `Слой "${layer.name}"`
            headerRow.getCell(1).font = cellStyle
            let columnNumber = 2
            // TODO переделать layersInteractiveSettings больше нет!
            /* this.layersInteractiveSettings[layer.guid].interactive.standardCard.fields.forEach((field) => {
                row.getCell(j).value = field.label
                row.getCell(j).font = cellStyle
                j++
              })
              */
            // set values
            let count = 1
            Object.values(layer.items).forEach((item) => {
              const row = worksheet.getRow(i++)
              row.getCell(1).value = count++
              let columnNumber = 2
              item.standardCardProperties.forEach((property) => {
                if (count < 3) {
                  // Наименования столбцов (свойств)
                  headerRow.getCell(columnNumber).value = property.name
                  headerRow.getCell(columnNumber).font = cellStyle
                }
                row.getCell(columnNumber++).value = property.value
              })
            })
            i++
          })
          const buffer = await workbook.xlsx.writeBuffer()
          let blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
          let url = window.URL.createObjectURL(blob)
          window.open(url)
          break
        case 'png':
          await MapManager.export(this.map, ExportType.PNG)
          break
        case 'geoTiff':
          const exportInfo = await MapManager.export(this.map, ExportType.GeoTIFF, false, false)
          await APIClient.shared.request(new DotNetSpatialServiceAPI.ConvertToGeoTiff(exportInfo))
          break
        case 'vector':
          if (this.getSelectedFeatures().length > 0) {
            this.$refs['export_features'].open('features', this.getSelectedFeatures())
          } else if (this.activeLayersLength > 0) {
            this.$refs['export_features'].open('layers', this.activeLayers)
          } else {
            this.showError('Отсутсвуют включенные слои')
          }
          break
        default:
          console.log('Неизвестный тип экспорта')
      }
    },
    exportLayer (layer) {
      const activeLayer = this.getActiveLayerByGuid(layer.guid)
      this.$refs['export_features'].open('layers', [activeLayer])
    },
    async importFeatures (type) {
      const activeLayerData = this.getActiveLayer(true)
      if (activeLayerData === false) {
        return
      }
      // const countFeaturesBefore = me.getActiveLayerFeatures()
      switch (type) {
        case 'file-dxf':
          let str = activeLayerData.layerData.source.geometryField
          this.importInVTLayer = false
          let layerDefaultImportSettings = activeLayerData.layerData.defaultImportSettings && Object.values(activeLayerData.layerData.defaultImportSettings).some((value) => {
            if (value) {
              return true
            }
          })
          if (layerDefaultImportSettings) {
            this.importDefaultImportSettings = activeLayerData.layerData.defaultImportSettings
          } else {
            this.importDefaultImportSettings = this.defaultImportSettings
          }
          switch (activeLayerData.layerData.source.type) {
            case 'Registry':
              if (activeLayerData.layerData.source.type === 'Registry' &&
                  activeLayerData.layerData.source.isVectorTile === true &&
                  activeLayerData.layerData.source.enableImport === true) {
                this.importInVTLayer = true
                this.importInVTLayerData = {
                  layer_id: activeLayerData.guid,
                  record_id: activeLayerData.layerData.source.geometryField
                }
                this.importLayerGeometryField = '' + str
              } else {
                return
              }
              break
            case 'Field':
              if (typeof str !== 'string') {
                this.showError('Импорт в выбранный слой невозможен')
                return
              }
              this.importLayerGeometryField = str.substr(str.indexOf('_') + 1, str.length - str.indexOf('_') - 2)
              break
            default:
              this.showError('Импорт в выбранный слой невозможен')
          }
          this.$refs['import_features'].showImportFileWindow()
          break
        case 'string-wkt':
          this.$refs['import_features'].showImportWKTWindow()
          break
        default:
          console.log('Неизвестный тип импорта')
      }
    },
    async getScreenImage (exportType, isBlob) {
      // exportType @bingo_soft/mapmanager/src/Domain/Model/Map/ExportType
      return (await MapManager.export(this.map, exportType, !isBlob)).file
    },
    intersectionFeatureCollection (data) {
      let layers = []
      data.features.forEach((feature) => {
        if (!layers.includes(feature.layer.properties.name)) {
          layers.push(feature.layer.properties.name)
        }
      })
      this.showIntersectionMessage(layers)
    },
    intersectionFeature (feature) {
      let layers = []
      MapManager.getFeatureIntersectedLayers(feature.feature).forEach((layer) => {
        if (!layers.includes(layer.properties.name)) {
          layers.push(layer.properties.name)
        }
      })
      this.showIntersectionMessage(layers)
    },
    showIntersectionMessage (layers) {
      this.intersection.layers.splice(0, this.intersection.layers.length)
      let message = ''
      if (layers.length === 0) {
        message = 'Пересечение с выбранными слоями не найдено'
      } else {
        message = 'Обнаружено пересечение со слоями:'
      }
      layers.forEach((layer) => {
        this.intersection.layers.splice(this.intersection.layers.length, 0, layer)
      })
      this.intersection.message = message
      this.intersection.showWindow = true
    },
    transform (coordinate, systemFrom, systemTo) {
      let systems = [
        3857,
        4326
      ]
      if (systemFrom === systemTo) {
        return coordinate
      }
      if (systems.indexOf(systemFrom) !== false && systems.indexOf(systemTo) !== false) {
        return OlProj.transform(
          coordinate,
          'EPSG:' + systemFrom,
          'EPSG:' + systemTo
        )
      }
      // error
      return coordinate
    },
    expand () {
      if (this.isEditor()) {
        return
      }
      this.isExpanded = !this.isExpanded
      if (this.expandedSettings.notExpandedStyle === null) {
        let tab = false
        let parent = this
        let tabSelectionHeight = 0
        let prev = null
        let previewBlocksUids = []
        let lastBlock = null
        while (parent !== undefined) {
          // vueDraggableBlocks
          switch (parent.$options['_componentTag']) {
            case 'responsive-previewer':
              // this.expandedSettings.vueDraggableBlocks = this.getHideBlocks(parent.$children[0], previewBlocksUids).map(
              this.expandedSettings.vueDraggableBlocks = this.getAllChildrenPreviewBlocks(parent, previewBlocksUids).map(
                (item) => {
                  return {
                    component: item,
                    widthByDefault: item.$el.style.width,
                    minWidthByDefault: item.$el.style.minWidth
                  }
                }
              )
              break
              // сохраняем uid блока с картой
            case 'preview-block':
              previewBlocksUids.push(parent._uid)
              lastBlock = parent
              break
            case 'grid-item':
              this.expandedSettings.containers.push({
                container: parent,
                defaultStyleZIndex: parent.style.zIndex,
                defaultStyleTransform: parent.style.transform,
                defaultStyleTop: parent.style.top,
                defaultStyleLeft: parent.style.left
              })
              break
            case 'Dashboard':
              this.expandedSettings.dashboard.dom = parent.$el.querySelector('.dashboard_form')
              if (this.expandedSettings.dashboard.dom !== null) {
                this.expandedSettings.dashboard.defaultWidth = this.expandedSettings.dashboard.dom.style.width
              }
              break
              // панель вкладки
            case 'el-tab-pane':
              if (tab) {
                tabSelectionHeight += tab.$el.offsetParent.offsetTop
              }
              tab = parent
              break
              // фрейм
            case 'dashboard':
              tabSelectionHeight = 0
              tab = false
              break
          }
          if (this.getInterfaceEditorVersion() === 2) {
            if (parent.$options['_componentTag'] === 'InterfaceViewerV2') {
              this.expandedSettings.v2.interfaceViewer = parent
              this.expandedSettings.v2.container = parent.$el.querySelector('.container')
              this.expandedSettings.v2.defaultContainerCssText = this.expandedSettings.v2.container.style.cssText
            }
          }
          prev = parent
          parent = parent.$parent
        }
        this.expandedSettings.tab = tab || lastBlock
        this.expandedSettings.tabSelectionHeight = tabSelectionHeight
        this.expandedSettings.notExpandedStyle = this.$refs['map-container']
      }
      if (!this.resizeObserver) {
        this.resizeObserver = new ResizeObserver(entities => {
          this.resizeMap()
        })
      }
      if (this.isExpanded) {
        this.resizeObserver.observe(this.expandedSettings.tab.$el)
      } else {
        this.resizeObserver.unobserve(this.expandedSettings.tab.$el)
        if (this.hideToolsPanelAfterExpand) {
          this.$set(this, 'showToolsPanel', false)
        }
      }
      this.resizeMap()
      this.$refs['tools'].changeActive('expand')
    },
    getAllChildrenPreviewBlocks (item, uuids) {
      let result = []

      if (item.$options['_componentTag'] === 'preview-block' && !uuids.includes(item.$options['_componentTag'])) {
        result.push(item)
      }
      for (let i = 0; i < item.$children.length; i++) {
        result.push(...this.getAllChildrenPreviewBlocks(item.$children[i], uuids))
      }

      return result
    },
    resizeMap () {
      let mapContainer = this.$refs['map-container']
      // get tab and tab containers
      let top = this.expandedSettings.tab.$el.offsetParent.offsetTop + this.expandedSettings.tabSelectionHeight
      let left = this.expandedSettings.tab.$el.offsetParent.offsetLeft
      let height = this.expandedSettings.tab.$el.clientHeight - this.expandedSettings.tabSelectionHeight
      let width = this.expandedSettings.tab.$el.clientWidth
      if (this.isExpanded) {
        this.expandedSettings.containers.forEach((item) => {
          item.container.style.zIndex = '1'
          item.container.style.transform = ''
          item.container.useCssTransforms = false
        })
        // map container changes
        mapContainer.style.position = 'fixed'
        mapContainer.style.width = (width - 20) + 'px'
        mapContainer.style.height = (height - 20) + 'px'
        mapContainer.style.zIndex = '999'
        mapContainer.style.top = (top + 10) + 'px'
        mapContainer.style.left = (left + 10) + 'px'
        mapContainer.style.transform = ''
        if (this.expandedSettings.dashboard.dom !== null) {
          this.expandedSettings.dashboard.dom.style.width = '0'
        }
        if (this.getInterfaceEditorVersion() === 2) {
          this.getCard().hideButtonsBlock()
          if (this.expandedSettings.v2.container) {
            this.expandedSettings.v2.container.style.cssText = 'width: 1px;'
          }
        }
        this.expandedSettings.vueDraggableBlocks.forEach((item) => {
          item.component.$el.style.width = 0
          item.component.$el.style.minWidth = 0
        }, this)
      } else {
        mapContainer.style = this.expandedSettings.notExpandedStyle
        this.expandedSettings.containers.forEach((item) => {
          item.container.style.left = item.defaultStyleLeft
          item.container.style.top = item.defaultStyleTop
          item.container.style.zIndex = item.defaultStyleZIndex
          item.container.style.transform = item.defaultStyleTransform
          item.container.useCssTransforms = true
        })
        if (this.expandedSettings.dashboard.dom !== null) {
          this.expandedSettings.dashboard.dom.style.width = this.expandedSettings.dashboard.defaultWidth
        }
        if (this.getInterfaceEditorVersion() === 2) {
          this.getCard().showButtonsBlock()
          if (this.expandedSettings.v2.container) {
            this.expandedSettings.v2.container.style.cssText =
                this.expandedSettings.v2.defaultContainerCssText
          }
        }
        this.expandedSettings.vueDraggableBlocks.forEach((item) => {
          item.component.$el.style.width = item.widthByDefault
          item.component.$el.style.minWidth = item.minWidthByDefault
        })
      }
      let me = this
      me.$nextTick(() => {
        setTimeout(() => MapManager.updateSize(me.map), 0)
        me.setComponentSize()
      })
    },
    highlightFeatures (features) {
      features.forEach((feature) => { MapManager.highlightFeature(feature) })
    },
    unhighlightFeatures (features) {
      features.forEach((feature) => { MapManager.unhighlightFeature(feature) })
    },
    getSelectedFeatures () {
      return this.activeFeatures
    },
    print () {
      MapManager.export(this.map)
    },
    changeFooterTargetCS (newVal) {
      this.$set(this.footerData, 'targetCS', newVal)
      this.loadAndRegisterCS(newVal)
      this.applyShowMouseCoordinatesInteractive(this.showMouseCoordinates, this.syncControl)
    },
    saveGeometry (item, geom, layerSettings) {
      if (!geom) {
        geom = item
      }
      if (layerSettings.source.type === 'Field') {
        this.getModel()[layerSettings.source.geometryField] = this.getActiveLayerFeatures()
        this.zoomOnFeature(geom, false)
        this.showSuccess('геометрия обновлена')
        return
      }

      let geometry = MapManager.getGeometryAsText(geom, 'GeoJSON', this.map.getSRSId(), layerSettings.source.nativeCoordinateSystemId)
      // let id = item.openCard.properties['attr_' + layerSettings.interactive.card.fieldId + '_']
      let id = item.feature.values_.id

      let formData = new FormData()
      // formData.append('card_id', layerSettings.interactive.card.card.cardId)
      // record id
      formData.append('id', id)
      // record guid
      formData.append('guid', '"' + item.feature.values_.guid + '"')
      // record geometry
      let geometryField = `attr_${layerSettings.source.geometryField}_`
      if (layerSettings.source.type === 'Query') {
        geometryField = layerSettings.source.geometryField
      }
      formData.append(
        geometryField,
        '"' + JSON.stringify(JSON.parse(geometry).geometry).replaceAll('"', '\\"') + '"'
      )
      let entityId = layerSettings.source.entityId
      if (layerSettings.source.type === 'Query') {
        entityId = layerSettings.interactive.card.card.registryId
      }
      // card id
      this.$http.put(
        `${this.$config.api}/registryservice/registry/${entityId}/records/${id}`,
        formData,
        {
          hideNotification: true,
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      )
        .then(() => {
          this.zoomOnFeature(geom, false)
          this.showSuccess('геометрия обновлена')
        })
        .catch(() => {
          this.showError('обновление геометрии не произошло')
        })
    },
    async doAction (toolId, $event) {
      try {
        await ActionExecutor.execute(this, { readonly: this._isReadonly, pluginName: this.pluginName, action: $event, event: event })
      } catch (error) {
        console.error('Ошибка действия кнопки', error)
      }
    },
    async webGLLayerClearHighlightedFeature () {
      // this.activeLayers.forEach((layer) => {
      //   if (layer.layerData.source.isVectorTile && layer.layerData.source.isWebGLVectorTile) {
      //     layer.layer.setHighlightFeatureId('')
      //   }
      // })
      this.activeLayers.forEach((layer) => {
        if (layer.layerData.source.isVectorTile && layer.layerData.source.isWebGLVectorTile) {
          layer.layer.setSelectedObjects([])
          return false
        }
      })
    },
    async getWebGLFeaturesIntersectionInRectangle (data) {
      // Получает координаты в пикселях нарисованного прямоугольника для выбора фич
      let rectangleGeometry = this.currentInteractiveOnMap.getInteraction().getGeometry().getCoordinates()[0]
      let startPixels = MapManager.getPixelFromCoordinate(this.map, rectangleGeometry[0])
      let endPixels = MapManager.getPixelFromCoordinate(this.map, rectangleGeometry[2])
      const searchBounds = {
        x1: startPixels[0],
        y1: startPixels[1],
        x2: endPixels[0],
        y2: endPixels[1]
      }
      // Получает фичи со всех слоев webgl, которые пересекают нарисованный прямоугольник
      let webglFeaturesForSelection = this.map.getDeckGL().getDeckInstance().pickObjects({
        x: searchBounds.x1 < searchBounds.x2 ? searchBounds.x1 : searchBounds.x2,
        y: searchBounds.y1 < searchBounds.y2 ? searchBounds.y1 : searchBounds.y2,
        width: searchBounds.x1 < searchBounds.x2 ? searchBounds.x2 - searchBounds.x1 : searchBounds.x1 - searchBounds.x2,
        height: searchBounds.y1 < searchBounds.y2 ? searchBounds.y2 - searchBounds.y1 : searchBounds.y1 - searchBounds.y2,
      })
      // Добавляет фичи в массив для отображения в карточке свойств
      let featureIds = []
      webglFeaturesForSelection.forEach((info) => {
        const feature = MapManager.createFeatureFromText('1 1', GeometryFormat.Text, 3857)
        feature.setProperties(info.object.properties)
        data.add(feature)
        featureIds.push(info.object.properties.id)
      })
      // Подсвечивает фичи по id
      this.activeLayers.forEach((layer) => {
        if (layer.layerData.source.isVectorTile && layer.layerData.source.isWebGLVectorTile) {
          layer.layer.setSelectedObjects(Array.from(new Set(featureIds)))
          return false
        }
      })
    },
    async getWebGLFeaturesIntersectionInPolygon (data) {
      // Получает координаты нарисованного полигона для выбора фич и переводит в СК 4326, потому что в webgl фичи хранятся в это СК
      let coordinatesOfDrawnPolygon = this.drawnPolygonInSelectionMode.getGeometry().getCoordinates()[0]
      for (let i = 0; i < coordinatesOfDrawnPolygon.length; i++) {
        coordinatesOfDrawnPolygon[i] = this.transform(coordinatesOfDrawnPolygon[i], 3857, 4326)
      }
      // Получает фичи со всех слоев webgl, которые пересекают нарисованный полигон
      let webglFeaturesForSelection = []
      this.activeLayers.forEach((layer) => {
        if (layer.layerData.source.isVectorTile && layer.layerData.source.isWebGLVectorTile) {
          webglFeaturesForSelection = webglFeaturesForSelection.concat(layer.layer.getFeaturesThatIntersectPolygon([coordinatesOfDrawnPolygon]))
        }
      })
      // Добавляет фичи в массив для отображения в карточке свойств
      let featureIds = []
      webglFeaturesForSelection.forEach((info) => {
        const feature = MapManager.createFeatureFromText('1 1', GeometryFormat.Text, 3857)
        feature.setProperties(info.properties)
        data.add(feature)
        featureIds.push(info.properties.id)
      })
      // Подсвечивает фичи по id
      this.activeLayers.forEach((layer) => {
        if (layer.layerData.source.isVectorTile && layer.layerData.source.isWebGLVectorTile) {
          layer.layer.setSelectedObjects(Array.from(new Set(featureIds)))
          return false
        }
      })
    },
    async getVectorTileFeaturesIntersectionInPolygon(data) {
      let geometryExtent = null
      if (this.currentInteraction === INTERACTION_MODES.SELECT.RECTANGLE) {
        geometryExtent = this.currentInteractiveOnMap.getInteraction().getGeometry().getExtent()
      } else if (this.currentInteraction === INTERACTION_MODES.SELECT.POLYGON) {
        geometryExtent = this.drawnPolygonInSelectionMode.getGeometry().getExtent()
      }
      // Перебирает все VectorTile слои
      for (let i = 0; i < this.activeLayers.length; i++) {
        let featuresIds = []
        let layer = this.activeLayers[i]
        if (layer.layer.constructor.name !== 'VectorTileLayer') {
          continue
        }
        let renderFeatures = layer.layer.getSource().getFeaturesInExtent(geometryExtent)
        // Выбирает только те фичи, которые относятся к слою
        renderFeatures = renderFeatures.filter((rf) => {
          return rf.getProperties().layer_guid === layer.guid
        })
        let coordinatesOfDrawnPolygon = null
        if (this.currentInteraction === INTERACTION_MODES.SELECT.RECTANGLE) {
          coordinatesOfDrawnPolygon = this.currentInteractiveOnMap.getInteraction().getGeometry().getCoordinates()
        } else if (this.currentInteraction === INTERACTION_MODES.SELECT.POLYGON) {
          coordinatesOfDrawnPolygon = this.drawnPolygonInSelectionMode.getGeometry().getCoordinates()
        }
        let featuresForSelection = layer.layer.getFeaturesThatIntersectTheGeometry(renderFeatures, coordinatesOfDrawnPolygon)
        featuresForSelection.forEach((featureForSelect) => {
          const feature = MapManager.createFeatureFromText('1 1', GeometryFormat.Text, 3857)
          feature.setProperties(featureForSelect.properties)
          data.add(feature)
          featuresIds.push(featureForSelect.properties.id)
        })

        layer.layer.setSelectedFeaturesId(featuresIds)
        await layer.layer.layer.changed()
      }
    },
    async getVectorTileFeaturesIntersectionInPoint(data) {
      if (MapManager.getLayers(this.map, SourceType.VectorTile).length < 0) {
        return
      }
      let vectorTileFeaturesCollection = MapManager.getFeaturesAtPixelByLayerSourceType(this.map, this.pixelOfLastClick, SourceType.VectorTile, 5)
      // Перебирает все VectorTile слои
      for (let i = 0; i < this.activeLayers.length; i++) {
        let featuresIds = []
        let layer = this.activeLayers[i]
        if (layer.layer.constructor.name !== 'VectorTileLayer') {
          continue
        }
        // Выбирает только те фичи, которые относятся к слою
        let vectorTileFeatures = vectorTileFeaturesCollection.getFeatures().filter((feature) => {
          return feature.getFeature().getProperties().layer_guid === layer.guid
        })
        vectorTileFeatures.forEach((feature) => {
          data.features.push(feature)
          featuresIds.push(feature.getProperties().id)
        })
        layer.layer.setSelectedFeaturesId(featuresIds)
        await layer.layer.layer.changed()
      }
    },
    onMapClickCallback(event) {
      let hasWebGlFeatures = false
      let hasVectorTileFeatures = false
      // Удаляет выделение webgl фич
      this.webGLLayerClearHighlightedFeature()
      // Удаляет выделение vectorTile фич
      this.vectorTileLayerClearHighlightedFeature()
      // Записывает пиксель клика
      this.pixelOfLastClick = event && event['pixel'] ? event['pixel'] : null
      if (!this.currentInteraction === INTERACTION_MODES.SELECT.POINT || !this.pixelOfLastClick) {
        return
      }

      // Проверяет есть ли webGl фичи в точке
      if (MapManager.getLayers(this.map, SourceType.WebGLVectorTile).length > 0) {
        let webGLVectorTileLayer = this.activeLayers.find((layer) => { return layer.layerData.source.isVectorTile && layer.layerData.source.isWebGLVectorTile})
        let x = this.pixelOfLastClick[0]
        let y = this.pixelOfLastClick[1]
        let deckInstance = webGLVectorTileLayer.layer.getMap().getDeckGL().getDeckInstance()
        let pickInfos = deckInstance.pickMultipleObjects({ x, y, radius: 10, depth: 10 })
        hasWebGlFeatures = pickInfos.length > 0
      }

      // Проверяет есть ли vectorTile фичи в точке
      if (MapManager.getLayers(this.map, SourceType.VectorTile).length > 0) {
        hasVectorTileFeatures = MapManager.hasFeaturesAtPixelByLayerSourceType(this.map, this.pixelOfLastClick, SourceType.VectorTile, 5)
      }

      // Если не нашли webgl и vectortile фичи - нет смысла проверять векторные
      if (!hasWebGlFeatures && !hasVectorTileFeatures) {
        return
      }
      // Проверяет есть ли векторные фичи в точке
      let hasVectorFeatures = MapManager.hasFeaturesAtPixelByLayerSourceType(this.map, this.pixelOfLastClick, SourceType.Vector)
      let newVectorGuids = []
      let oldVectorGuids = []
      if (hasVectorFeatures) {
        let vectorTileFeaturesCollection = MapManager.getFeaturesAtPixelByLayerSourceType(this.map, this.pixelOfLastClick, SourceType.Vector)
        for (const f of vectorTileFeaturesCollection.getFeatures()) {
          newVectorGuids.push(f.getProperties().guid)
          // Если прокол точкой выключен - берем только первую фичу
          if (!this.isSelectInteractionPin) {
            break
          }
        }
        this.activeFeatures.forEach((f) => {
          oldVectorGuids.push(f.properties.guid)
        })
      }

      /*Если выбрали точку, где есть ВТ фичи и векторные фичи и при этом векторные не изменились - нужно запустить onFeaturesClick и передать в него векторные фичи
      т.к. Openlayers не запускает колбэк при повторном выборе таких же фич*/
      if ((hasVectorTileFeatures || hasWebGlFeatures) && hasVectorFeatures && newVectorGuids.sort().toString() === oldVectorGuids.sort().toString()) {
        let features = new FeatureCollection()
        this.activeFeatures.forEach((f) => {
          features.add(f.item)
        })
        this.onFeaturesClick(features)
        //Если выбрали точку, где есть ВТ фичи - запускает onFeaturesClick, т.к. иначе колбэк не вызовется
      } else if ((hasVectorTileFeatures || hasWebGlFeatures) && !hasVectorFeatures) {
        let features = new FeatureCollection()
        this.onFeaturesClick(features)
      }
    },
    async vectorTileLayerClearHighlightedFeature () {
      this.activeLayers.forEach((layer) => {
        if (layer.layerData.source.isVectorTile && !layer.layerData.source.isWebGLVectorTile) {
          layer.layer.setSelectedFeaturesId([])
          layer.layer.layer.changed()
        }
      })
    },
    async getWebGlFeaturesIntersectionInPoint(data) {
      if ( MapManager.getLayers(this.map, SourceType.WebGLVectorTile).length < 1) {
        return
      }
      let featuresIds = []
      let webGLVectorTileLayer = this.activeLayers.find((layer) => { return layer.layerData.source.isVectorTile && layer.layerData.source.isWebGLVectorTile})
      const x = this.pixelOfLastClick[0]
      const y = this.pixelOfLastClick[1]

      const deckInstance = webGLVectorTileLayer.layer.getMap().getDeckGL().getDeckInstance()
      const pickInfos = deckInstance.pickMultipleObjects({ x, y, radius: 10, depth: 10 })
      pickInfos.forEach((item) => {
        const feature = MapManager.createFeatureFromText('1 1', GeometryFormat.Text, 3857)
        feature.setProperties(item.object.properties)
        data.features.push(feature)
        featuresIds.push(feature.getProperties().id)
      })

      webGLVectorTileLayer.layer.setSelectedObjects(featuresIds)
    }
  }
}
</script>

  <style scoped>
  .map {
    width: 100%;
    height: 100%;
    z-index: 99;
    position: relative;
  }
  .mapCollapsed {
    width: 100%;
    height: 45%;
    z-index: 99;
  }
  .map-container {
    width: 100%;
    height: 100%;
  }
  .tools-panel, .layers {
    position: absolute;
    left: 10px;
    top: 10px;
    z-index: 999;
    height: calc(100% - 26px);
  }
  .zoom {
    position: absolute;
    right: 10px;
    bottom: 45%;
    height: 72px;
    z-index: 999;
  }
  .interactive-panel {
    position: absolute;
    right: 10px;
    top: 65px;
    z-index: 999;
    width: 300px;
    height: calc(100% - 65px - 108px);
    background: white;
  }
  .slide-fade-enter-active {
    transition: all .3s ease;
  }
  .slide-fade-leave-active {
    transition: all .2s cubic-bezier(1.0, 0.5, 0.8, 1.0);
  }
  .slide-fade-enter, .slide-fade-leave-to
    /* .slide-fade-leave-active до версии 2.1.8 */ {
    transform: translateX(10px);
    opacity: 0;
  }
  .streetViewContainerCollapsed {
    width: 100%;
    height: 0;
  }
  .streetViewContainer {
    width: 100%;
    height: 55%;
  }
  </style>
  <style>
    .enter-address-message-box {
      width: auto;
      max-width: 420px;
    }
    .create-geometry-by-vertices-msgbox {
      width: auto;
    }
    .olscaleline {
      position: absolute;
      bottom: 10px;
      left: 10px;
    }
    .olscale {
      background-color: initial;
      bottom: 38px;
      right: 10px;
    }
    .olscale input {
      width: 95px;
      padding: 4px;
      border: 1px #eee solid;
      font-size: 12px;
    }
    .olscale input:focus {
      outline: none;
    }
  </style>
